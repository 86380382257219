import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Text } from '@fluentui/react/lib/Text';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { ITeam } from './TMTeamList';
// import { ITemplateProps } from './Template';
import { Dropdown, IDropdownOption, DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import * as jwt from 'jsonwebtoken'
import { useBoolean } from '@fluentui/react-hooks';
import * as InnoStyles from './../styles';
import RWAGlobalContext from '../context/RWAGlobalContext';
import TMMessageBox from './TMMessageBox';
import { MessageBarType } from '@fluentui/react';
import * as DataLayer from '../DataLayer'
import moment from 'moment';

export interface IExtendLifecycleProps {
    teamId: string;
}

const DEBUG = false;

const ExtendLifecycle = (props: IExtendLifecycleProps) => {
    const { AADToken, GraphToken, languageDictionary, checkTokens, TenantID } = useContext(RWAGlobalContext);
    const his = useHistory();
    const [loading, setLoading] = useState(false);
    const [team, setTeam] = useState<ITeam>();
    const [defaultLifeCycleKey, setDefaultLifeCycleKey] = useState<string>('');
    const [extendLifecycle, setExtendLifecycle] = useState<IDropdownOption>({ key: '', text: '' });

    const [archiving, setArchiving] = useState(false);
    const [saving, setSaving] = useState(false);
    const [lifecycleChanged, setLifecycleChanged] = useState(false);
    // MessageBox state
    const [showMessageBox, { toggle: toggleMessageBox }] = useBoolean(false);
    const [msgBoxMessage, setMsgBoxMessage] = useState('');
    const [msgBoxType, setMsgBoxType] = useState<MessageBarType>(MessageBarType.info);

    const extendLifecycleOptions: IDropdownOption[] = [
        { key: 'months', text: languageDictionary.Months, itemType: DropdownMenuItemType.Header },
        { key: '3', text: languageDictionary.Option3Months },
        { key: '6', text: languageDictionary.Option6Months },
        { key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider },
        { key: 'years', text: languageDictionary.Years, itemType: DropdownMenuItemType.Header },
        { key: '12', text: languageDictionary.Option1Year },
        { key: '24', text: languageDictionary.Option2Years },
        { key: '36', text: languageDictionary.Option3Years }
    ];

    useEffect(() => {
        const retrieveTeams = async () => {

            //Getting user joined teams and filtering the team
            try {
                setLoading(true);
                await checkTokens();

                let joinedTeams: any[] = [];
                let teamSelected: ITeam;

                const decoded: { [key: string]: any; } = jwt.decode(AADToken) as { [key: string]: any; };
                joinedTeams = await DataLayer.getUsersJoinedTeams(decoded.oid, GraphToken);

                DEBUG && console.info('ExtendLifecycle joinedTeams: ', { joinedTeams });

                teamSelected = joinedTeams.filter(team => team.id === props.teamId)[0];
                if (!teamSelected) {
                    throw new Error("Team not found.");
                }
                const teamMembers = await DataLayer.getTeamMembers(teamSelected?.id, GraphToken);
                teamSelected.owners = teamMembers.value.filter((member: any) => member.roles.includes('owner')).map((item: any) => { return { text: item.displayName, id: item.userId, title: item.displayName, optionalText: item.email } as IPersonaProps });

                if (teamSelected.owners.filter(itm => itm.id === decoded.oid)[0] !== undefined) {
                    setTeam(teamSelected);
                } else {
                    throw new Error("You are not the owner of this team.");
                }

                setLoading(false);

            } catch (error: any) {
                setMsgBoxMessage('Error while getting the team: ' + error?.message);
                setMsgBoxType(MessageBarType.error);
                toggleMessageBox();
                redirect();
            }
        }

        retrieveTeams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AADToken, GraphToken])


    const handleLifecycleChange = (e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption<any>): void => {
        if (item) {
            setDefaultLifeCycleKey(item?.key as string)
            setExtendLifecycle(item)
            setLifecycleChanged(true)
        }
    }

    const redirect = (): void => {
        setTimeout(() => {
            his.push('/', { Home: true });
            setLoading(false);
        }, 5000)
    }

    //Handling extending the lifecycle
    const handleSave = async () => {
        setSaving(true);
        let extendedLifecycleDate: any;

        //Add team expiration date
        extendedLifecycleDate = moment().add(extendLifecycle?.key, 'M');
        const editTeamSuccess = await DataLayer.editTeam({
            id: team?.id,
            lifecycle: lifecycleChanged ? extendedLifecycleDate : '',
            updates: {
                name: false,
                owners: false,
                template: false,
                lifecycle: lifecycleChanged
            }
        }, AADToken, TenantID);

        DEBUG && console.info({ editTeamSuccess });
        if (editTeamSuccess === 200) {
            setMsgBoxMessage(languageDictionary.TeamManagement.ChangesToTeamAppliedText);
            setMsgBoxType(MessageBarType.success);
            toggleMessageBox();
            redirect();
        } else {
            setMsgBoxMessage(languageDictionary.TeamManagement.ErrorApplyingChangesText + editTeamSuccess);
            setMsgBoxType(MessageBarType.error);
            toggleMessageBox();
        }
        setSaving(false);
    }

    //Handling team archiving
    const onArchiveTeam = async () => {
        setArchiving(true);

        const reply = await DataLayer.archiveTeam(team?.id as string, AADToken);

        if (reply.data === 202) {
            setMsgBoxMessage(languageDictionary.TeamManagement.TeamSuccessArchivedText);
            setMsgBoxType(MessageBarType.success);
            toggleMessageBox();
            redirect();
        } else {
            setMsgBoxMessage(languageDictionary.TeamManagement.ErrorArchivingTeamText + reply.data);
            setMsgBoxType(MessageBarType.error);
            toggleMessageBox();
        }
        setArchiving(false);
    }

    DEBUG && console.info('ExtendLifecycle Team: ', { team });

    return (
        <Stack as="fieldset" styles={InnoStyles.fieldSetStyles} role="form">
            <TMMessageBox visible={showMessageBox} message={msgBoxMessage} messageType={msgBoxType} timeout={7000} />
            <Text as="legend" styles={InnoStyles.legendStyles} variant="large">{`Extend team lifecycle: ${(loading || !team) ? '' : team?.displayName}`}</Text>&nbsp;
            {loading ?
                <StackItem styles={InnoStyles.verticallyCenteredStyles}>
                    <Spinner size={SpinnerSize.large} label={languageDictionary.PeoplePicker.LoadingText} />
                </StackItem> :
                <Stack>
                    <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                        <StackItem grow styles={InnoStyles.stackItemStyles}>
                            <Dropdown
                                options={extendLifecycleOptions}
                                label={'You can choose to archive the team or extend it\'s lifecycle by'}
                                onChange={handleLifecycleChange}
                                placeholder={languageDictionary.SelectAnOption}
                                selectedKey={defaultLifeCycleKey} />
                        </StackItem>
                    </Stack>
                    <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
                        <StackItem>
                            <PrimaryButton text={languageDictionary.MyTeamsDialog.SaveButtonText} styles={{ root: { marginRight: 10 } }} onClick={handleSave} disabled={((!team) || (saving || !lifecycleChanged))} />
                            <PrimaryButton text={languageDictionary.MyTeamsDialog.ArchiveTeamButtonText} onClick={onArchiveTeam} disabled={(!team) || archiving} />
                        </StackItem>
                        <StackItem styles={InnoStyles.verticallyCenteredStyles}>
                            {(archiving || saving) && (<Spinner size={SpinnerSize.medium} />)}
                        </StackItem>
                    </Stack>
                </Stack>
            }
        </Stack>
    )
}

export default ExtendLifecycle;
