import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import * as jwt from 'jsonwebtoken';
import moment from 'moment';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { useBoolean } from '@fluentui/react-hooks/lib/useBoolean';
import * as DataLayer from './../DataLayer';
import * as CachedDataLayer from '../CachedDataLayer';
import RWAGlobalContext from './../context/RWAGlobalContext';
import TMTeamList, { ITeam } from './TMTeamList';
import { MyTeamsDialog } from './MyTeamsDialog';
import { ITemplateProps } from './Template';
import * as InnoStyles from './../styles';
import TMMessageBox from './TMMessageBox';
import { MessageBarType } from '@fluentui/react';


enum TeamFilterType {
  OWNER,
  MYOWNED,
  SEARCH
}

export interface ITMTeamManagementProps {
  adminMode: boolean;
  refreshTeams?: boolean; // value change triggers refresh
}
const DEBUG = false;

export const TMTeamManagement: FunctionComponent<ITMTeamManagementProps> = (props: ITMTeamManagementProps): ReactElement => {
  const { AADToken, GraphToken, languageDictionary, checkTokens, TenantID } = useContext(RWAGlobalContext);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [filteredTeams, setFilteredTeams] = useState<ITeam[]>([]);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [myTeamsPageCount, setMyTeamsPageCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [myJoinedTeams, setMyJoinedTeams] = useState([]);
  const [editDialogHidden, { toggle: toggleEditDialogHidden }] = useBoolean(true);
  const [selectedTeam, setSelectedTeam] = useState<ITeam>();
  const [templates, setTemplates] = useState<ITemplateProps[]>([]);
  const [searchFilterToggled, { toggle: toggleSearchFilter }] = useBoolean(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');

  // MessageBox state
  const [showMessageBox, { toggle: toggleMessageBox }] = useBoolean(false);
  const [msgBoxMessage, setMsgBoxMessage] = useState('');
  const [msgBoxType, setMsgBoxType] = useState<MessageBarType>(MessageBarType.info);

  const filterTeams = (team: ITeam[], filterType: TeamFilterType, searchTerm?: string) => {
    let newTeam: ITeam[] = [];
    switch (filterType) {
      case TeamFilterType.OWNER:
        newTeam = team.filter(team => team.owners.length < 2)
        break;

      case TeamFilterType.MYOWNED:
        const decoded: { [key: string]: any; } = jwt.decode(AADToken) as { [key: string]: any; };
        newTeam = team.filter(team => team.owners.includes(decoded.name));
        break;

      case TeamFilterType.SEARCH:
        newTeam = team.filter(team => team.displayName.toLowerCase().indexOf((searchTerm as string).toLowerCase()) > -1)
        break;

      default:
        break;
    }
    return newTeam.sort((a: any, b: any) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : -1);
  }

  const handleTooFewOwnersChange = (_ev: any, isChecked?: boolean) => {
    if (isChecked) {
      setFilteredTeams([...filterTeams(teams, TeamFilterType.OWNER)]);
      setFiltered(true);
    } else {
      setFiltered(false);
    }
  }

  const BuildBatchRequestForTeamDetailsAndMembers = (teamIDs: any, startIndex: number, endIndex: number) => {
    let requestList: any = {
      requests: []
    };
    for (let i = startIndex; i < endIndex; i++) {
      requestList.requests.push({
        id: i,
        method: "GET",
        url: `/teams/${teamIDs[i]}`
      });
      requestList.requests.push({
        id: (endIndex + i),
        method: "GET",
        url: `/teams/${teamIDs[i]}/members`
      });
    }
    return requestList;
  };

  /*
  const BuildBatchRequestForChannels = (teamIDs: any) => {
    let requestList:any = { 
      requests: []
    };

    teamIDs.forEach((tId:any, idx:number) => {
      requestList.requests.push({
        id: idx,
        method: "GET",
        url: `/teams/${tId.id}/channels`
      });
    });
    return requestList;
  }*/

  const retrievePagedGroups = async (nextLink: string, storage: any[], token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      DataLayer.getGraphNextLinkContent(nextLink, token).then(result => {
        result.value.forEach((group: any) => {
          storage.push(group.id);
        });
        if (result['@odata.nextLink'] !== undefined) {
          retrievePagedGroups(result['@odata.nextLink'], storage, token).then(res => {
            if (res.value !== undefined) {
              resolve([...storage, res.value.map((itm: any) => itm.id)]);
            } else {
              resolve([...storage, res.map((itm: any) => itm)]);
            }
          });
        } else {
          resolve(storage);
        }
      })
    });
  }

  useEffect(() => {
    const retrieveTeams = async () => {
      setTeams([]);
      setLoading(true);
      await checkTokens();
      let teamIds: any[] = [];
      let extensionInfo: any[] = [];
      let searchIds: any[] = [];
      if (props.adminMode) { // manage teams: retrieve all teams 
        if (searchTerm !== undefined && searchTerm.length >= 3 && searchTerm !== '') {
          const sResult = await DataLayer.searchGroups({ name: searchTerm }, AADToken);
          if (sResult.length > 0) {
            sResult.forEach((element: any) => {
              searchIds.push(element.id);
            });
          } else {
            setMsgBoxMessage(languageDictionary.TeamManagement.NoSearchResultsFound);
            setMsgBoxType(MessageBarType.error);
            toggleMessageBox();
            setMyTeamsPageCount(0);
            setLoading(false);
            return;
          }
        }
        const storedGroups = await DataLayer.getAllAdminGroups(TenantID);
        if (storedGroups === undefined || storedGroups.length === 0) {
          const groups = await DataLayer.getGroups(AADToken);
          groups.value.forEach((group: any) => {
            teamIds.push(group.id);
            if (group['ext0exj36kb_TeamsMate'] !== undefined) {
              extensionInfo.push({ id: group.id, extensionInfo: group['ext0exj36kb_TeamsMate'] });
            }
          });
          if (groups['@odata.nextLink']) {
            teamIds = await retrievePagedGroups(groups['@odata.nextLink'], teamIds, AADToken);
          }
          DataLayer.setAllAdminGroups(teamIds, TenantID);
          DataLayer.setAdminExtensionInfo(extensionInfo, TenantID);
        } else {
          teamIds = await DataLayer.getAllAdminGroups(TenantID);
        }
        setMyTeamsPageCount(Math.ceil((teamIds.length / 10))) // Calculate the number of pages (10 items per page)

      } else { // my teams: retrieve user joined teams
        let joinedTeams;
        if (myJoinedTeams.length === 0 || searchTerm !== '') {
          const decoded: { [key: string]: any; } = jwt.decode(AADToken) as { [key: string]: any; };

          joinedTeams = await DataLayer.getUsersJoinedTeams(decoded.oid, GraphToken);

          if (searchTerm !== undefined && searchTerm.length >= 3 && searchTerm !== '') {
            joinedTeams = filterTeams(joinedTeams, TeamFilterType.SEARCH, searchTerm);
            if (joinedTeams.length === 0) {
              setMsgBoxMessage(languageDictionary.TeamManagement.NoSearchResultsFound);
              setMsgBoxType(MessageBarType.error);
              toggleMessageBox();
              setMyTeamsPageCount(0);
              setLoading(false);
              return;
            }
          }

          teamIds = joinedTeams.sort((a: any, b: any) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : -1).map((joinedTeam: any) => joinedTeam.id);
          setMyJoinedTeams(joinedTeams);
        } else {
          teamIds = myJoinedTeams.sort((a: any, b: any) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : -1).map((joinedTeam: any) => joinedTeam.id);
        }
        setMyTeamsPageCount(Math.ceil((teamIds.length / 10))) // Calculate the number of pages (10 items per page)
      }

      // TESTING
      if (templates.length === 0) {
        const decoded: { [key: string]: any; } = jwt.decode(AADToken) as { [key: string]: any; };
        let tmpl: ITemplateProps[] = [];
        try {
          tmpl = await CachedDataLayer.getCachedAllTemplates(AADToken, decoded.tid);
          setTemplates(tmpl.sort((a: ITemplateProps, b: ITemplateProps) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
        } catch (e) {
          setTemplates(tmpl);
        }
      }

      if (searchIds !== undefined && searchIds.length > 0) {
        teamIds = searchIds;
        setMyTeamsPageCount(Math.ceil((teamIds.length / 10)));
      }

      let start = (currentPageNumber * 10) - (10);
      let end = Math.min(start + 10, teamIds.length);

      const bRequest = BuildBatchRequestForTeamDetailsAndMembers(teamIds, start, end);
      const batchResponse = await DataLayer.ExecuteGraphBatch(bRequest, AADToken);
      const teamsAndMembers = batchResponse.responses.map((item: any) => item.body);
      DEBUG && console.info('batchresponse', teamsAndMembers);
      // END TESTING

      /*
      const bReq = BuildBatchRequestForChannels(teamsAndMembers.filter((itm:any) => itm.id !== undefined));
      const bResp = await DataLayer.ExecuteGraphBatch(bReq, GraphToken);
      //const teamChannels = bResp.responses.map((item:any) => item.body);
      DEBUG && console.info('teamChannelsList', bResp.responses.map((item:any) => item.body));*/

      let retrievedTeams: number = 0;
      let latestTeamsState: ITeam[] = [];
      const extInfo = await DataLayer.getAdminExtensionInfo(TenantID);
      DEBUG && console.log('extInfo', extInfo);
      teamsAndMembers.filter((itm: any) => itm.id !== undefined).forEach(async (team: ITeam, idx: number) => {
        let owners: any;
        try {
          owners = teamsAndMembers.filter((mem: any) => mem['@odata.context'].indexOf(team.id) !== -1)[0].value.filter((item: any) => item.roles.includes('owner')).map((item: any) => { return { text: item.displayName, id: item.userId, title: item.displayName, optionalText: item.email } as IPersonaProps });
        } catch { }

        if (owners) {
          team.owners = owners;
        }
        if (extInfo !== undefined) {
          const ext = extInfo.filter(item => item.id === team.id);
          if (ext.length === 1) {
            team.ext0exj36kb_TeamsMate = ext[0].extensionInfo;
          }
        }

        setTeams(prevState => [...prevState, team].sort((a, b) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : -1));
        latestTeamsState.push(team);
        retrievedTeams++;
        if (retrievedTeams >= (start - end)) {
          setLoading(false);
        }
      });
      if (filtered) {
        DEBUG && console.log('filtered is true, setting filtered teams', filterTeams(teams, TeamFilterType.OWNER));
        setFilteredTeams(filterTeams(latestTeamsState, TeamFilterType.OWNER));
      }
    }
    retrieveTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.adminMode, props.refreshTeams, AADToken, GraphToken, currentPageNumber, searchFilterToggled]);


  const handlePageChange = (newPage: any) => {
    // PageIndex is zero based!!!! 0 = Page1 -> 1 = Page 2
    DEBUG && console.log('handlePageChange called');
    setCurrentPageNumber((newPage + 1));
  }

  const handleListItemClicked = (item: ITeam) => {
    setSelectedTeam(item);
    // If we're not in Admin-Mode (MyTeams for users) and the current user is NOT an owner of the team
    // Do NOT allow editing
    if (!props.adminMode) {
      const decoded: { [key: string]: any; } = jwt.decode(AADToken) as { [key: string]: any; };

      if (item.owners.filter(itm => itm.id === decoded.oid)[0] !== undefined) {
        toggleEditDialogHidden();
      }
    } else { // Admin mode....all good, he can edit everything!
      toggleEditDialogHidden();
    }
  }

  const handleSave = async (nameChanged: boolean, name: string, ownersChanged: boolean, templateChanged: boolean, template: ITemplateProps, addedOwner: IPersonaProps[], removedOwner: IPersonaProps[]) => {
    toggleEditDialogHidden();

    const editTeamSuccess = await DataLayer.editTeam({
      id: selectedTeam?.id,
      name: nameChanged ? name : '',
      lifecycle: templateChanged ? moment().add(template.lifecycle, 'M') : '',
      templateId: templateChanged ? template.key : '',
      templateName: templateChanged ? template.name : '',
      updates: {
        name: nameChanged,
        owners: ownersChanged,
        template: templateChanged,
        lifecycle: false
      },
      "addedowners": addedOwner.map(itm => { return itm.id; }),
      "removedowners": removedOwner.map(itm => { return itm.id; })
    }, AADToken, TenantID);
    if (editTeamSuccess === 200) {
      setMsgBoxMessage(languageDictionary.TeamManagement.ChangesToTeamAppliedText);
      setMsgBoxType(MessageBarType.success);
      toggleMessageBox();
      handlePageChange(0); // causes a refresh of data.
    } else {
      setMsgBoxMessage(languageDictionary.TeamManagement.ErrorApplyingChangesText + editTeamSuccess);
      setMsgBoxType(MessageBarType.error);
      toggleMessageBox();
    }
  }

  // Callback function for MyTeamsDialog -> "Archive" button clich
  const onArchiveTeam = async () => {
    const reply = await DataLayer.archiveTeam(selectedTeam?.id as string, AADToken);
    DEBUG && console.info('archive response:', reply);
    if (reply.data === 202) {
      toggleEditDialogHidden();
      setMsgBoxMessage(languageDictionary.TeamManagement.TeamSuccessArchivedText);
      setMsgBoxType(MessageBarType.success);
      toggleMessageBox();
    } else {
      toggleEditDialogHidden();
      setMsgBoxMessage(languageDictionary.TeamManagement.ErrorArchivingTeamText + reply.data);
      setMsgBoxType(MessageBarType.error);
      toggleMessageBox();
    }
  }

  const onSearchTermChangeTeamList = (ev: any, data: string) => {
    if (data !== '') {
      setSearchTerm(data);
      toggleSearchFilter();
    } else {
      setSearchTerm(undefined);
      toggleSearchFilter();
    }
  }

  return (
    <Stack>
      <TMMessageBox visible={showMessageBox} message={msgBoxMessage} messageType={msgBoxType} timeout={5000} />
      <Stack horizontal tokens={InnoStyles.formStackTokens}>
        <StackItem>
          <Text as="h2" variant="mediumPlus" styles={InnoStyles.h2Style}>
            {(props.adminMode ? languageDictionary.TeamManagement.AllTeamsText : languageDictionary.TeamManagement.MyTeamsText) + ' (' + (filtered ? filteredTeams.length : teams.length) + ')'}
          </Text>
        </StackItem>
        <StackItem styles={InnoStyles.verticallyCenteredStyles}>
          {loading && <Spinner size={SpinnerSize.medium} />}
        </StackItem>
        <StackItem styles={InnoStyles.verticallyCenteredStyles}>
          {!loading && (<Checkbox label={languageDictionary.TeamManagement.TooFewOwnersText} onChange={handleTooFewOwnersChange} checked={filtered}></Checkbox>)}
        </StackItem>
      </Stack>
      <MyTeamsDialog team={selectedTeam} hidden={editDialogHidden} toggleHideDialog={toggleEditDialogHidden} templates={templates} onArchiveTeam={onArchiveTeam} onSaveChanges={handleSave} />
      <TMTeamList teams={filtered ? filteredTeams : teams} pageCount={myTeamsPageCount} currentPage={currentPageNumber} onMyTeamsPageChange={handlePageChange} onListItemInvoke={handleListItemClicked} onSearchTermChange={onSearchTermChangeTeamList} />
    </Stack>
  );
}
