import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField } from '@fluentui/react/lib/TextField';
import * as DataLayer from '../DataLayer';
import * as Constants from '../Constants';
import { RWAGlobalContext } from '../context/RWAGlobalContext';
import * as InnoStyles from '../styles';


interface ITMTeamsReportRequestProps {
  userEmail?: string;
}

export const TMTeamsReportRequest: FunctionComponent<ITMTeamsReportRequestProps> = (props: ITMTeamsReportRequestProps): ReactElement => {
  const { AADToken, languageDictionary, checkTokens } = useContext(RWAGlobalContext);
  const [inactivityThreshold, setInactivityThreshold] = useState(6);
  const [email, setEmail] = useState(props.userEmail);
  const [emailValid, setEmailValid] = useState(false);
  const [sending, setSending] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFailure, setSendFailure] = useState(false);

  const inactivityThresholdOptions: IDropdownOption[] = [
    { key: 3, text: languageDictionary.Option3Months },
    { key: 6, text: languageDictionary.Option6Months }
  ];

  /**
   * Handle send button click
   */
  const handleSendClick = async () => {
    setSending(true);
    await checkTokens();
    DataLayer.sendTeamsReport({
      inactivityThresholdMonths: inactivityThreshold,
      email: email?.trim()
    }, AADToken)
      .then((statusCode) => {
        statusCode === 200 ? setSendSuccess(true) : setSendFailure(true);
      })
      .catch((error) => {
        setSendFailure(true);
        console.error(error);
      })
      .finally(() => {
        setSending(false);
      });
  }

  useEffect(() => {
    if (email) {
      setEmailValid(Constants.validEmail.test(email.trim()));
    } else {
      setEmailValid(false);
    }
  }, [email]);

  /**
   * Hide messages after a delay
   */
  useEffect(() => {
    if (sendSuccess) {
      setTimeout(() => { setSendSuccess(false); }, 20000);
    }
    if (sendFailure) {
      setTimeout(() => { setSendFailure(false); }, 10000);
    }
  }, [sendSuccess, sendFailure]);

  return (
    <Stack as="fieldset" styles={InnoStyles.fieldSetStyles} role="form">
      <Text as="legend" styles={InnoStyles.legendStyles} variant="large">{languageDictionary.Admin.RequestTeamsReportTitle}</Text>
      <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
        <StackItem grow styles={InnoStyles.stackItemStyles}>
          <Dropdown
            label={languageDictionary.Admin.InactivityThreshold}
            onChange={(_event, value) => setInactivityThreshold((value as IDropdownOption).key as number)}
            options={inactivityThresholdOptions}
            placeholder={languageDictionary.SelectAnOption}
            required
            selectedKey={inactivityThreshold} />
        </StackItem>
      </Stack>
      <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
        <StackItem grow styles={InnoStyles.stackItemStyles}>
          <TextField
            label={languageDictionary.Admin.TeamsReportEmail}
            onChange={(event) => setEmail((event.target as HTMLInputElement).value)}
            required
            value={email}>
          </TextField>
        </StackItem>
      </Stack>
      <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
        <StackItem>
          <PrimaryButton
            disabled={!emailValid || sending || sendSuccess}
            onClick={handleSendClick}
            styles={!emailValid || sending || sendSuccess ? InnoStyles.primaryButtonDisabledStyles : InnoStyles.primaryButtonStyles}
            text={languageDictionary.Admin.SendRequest}>
          </PrimaryButton>
        </StackItem>
        <StackItem styles={InnoStyles.verticallyCenteredStyles}>
          {sending && (<Spinner size={SpinnerSize.medium} />)}
        </StackItem>
      </Stack>
      <Stack horizontal tokens={InnoStyles.formStackTokens}>
        {sendSuccess &&
          <MessageBar isMultiline={false} messageBarType={MessageBarType.success}>
            <Text as="p">{languageDictionary.Admin.TeamsReportSent}</Text>
          </MessageBar>
        }
        {sendFailure &&
          <MessageBar isMultiline={false} messageBarType={MessageBarType.error}>
            <Text as="p">{languageDictionary.MessageError}</Text>
          </MessageBar>
        }
      </Stack>
    </Stack>
  );
}
