/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, ReactElement, useEffect, useContext } from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { IBasePickerSuggestionsProps, ValidationState, NormalPeoplePicker } from '@fluentui/react/lib/Pickers';
import { Label } from '@fluentui/react/lib/Label';
import * as DataLayer from '../DataLayer';
import { RWAGlobalContext } from './../context/RWAGlobalContext';
import * as InnoStyles from './../styles';

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested People',
  mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: false,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts',
};

interface IPeoplePickerProps {
  title: string,
  mandatory: boolean;
  selectedUsers?: IPersonaProps[];
  onChangeValue?: any;
  maxItems?: number;
}

export const RWAPeoplePicker: FunctionComponent<IPeoplePickerProps> = (props: IPeoplePickerProps): ReactElement => {
  
  const { GraphToken, languageDictionary } = useContext(RWAGlobalContext);
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<IPersonaProps[]>([]);
  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dummy, setDummy] = React.useState('');

  const picker = React.useRef(null);

  suggestionProps.loadingText = languageDictionary.PeoplePicker.LoadingText;
  suggestionProps.mostRecentlyUsedHeaderText = languageDictionary.PeoplePicker.MostRecentlyUsedHeaderText;
  suggestionProps.noResultsFoundText = languageDictionary.PeoplePicker.NoResultFoundText;
  suggestionProps.suggestionsAvailableAlertText = languageDictionary.PeoplePicker.SuggestionsAvailableAlertText;
  suggestionProps.suggestionsContainerAriaLabel = languageDictionary.PeoplePicker.SuggestionsContainerAriaLabel;
  suggestionProps.suggestionsHeaderText = languageDictionary.PeoplePicker.SuggestionHeaderText;

  useEffect(() => {
    const getPeople = async () => {
      let filter = ' ';
      const response: [] = await DataLayer.getPartialUsers(filter, GraphToken);
      let personas: IPersonaProps[] = [];
      response.forEach((itm:any, idx:number) => {
        if ((itm.userPrincipalName as string).toLowerCase().indexOf('#ext#') === -1) {          
          personas.push({
            text: itm.displayName,
            title: itm.displayName,
            imageInitials: itm.givenName.substr(0, 1) + itm.surname.substr(0, 1),
            secondaryText: itm.jobTitle,
            id: itm.id,
          });
        }
      });
      setPeopleList(personas);
      setMostRecentlyUsed(personas);
    }
    getPeople();
  }, [dummy]);

  const getInitialsFromPerson = (item:any) => {
    let _initials : string = '';

    // DisplayName is an email address
    if((item.displayName as string).indexOf('@') > -1) {
      _initials = (item.displayName as string).split('@')[0].split('.')[0].substr(0,1) + (item.displayName as string).split('@')[0].split('.')[1].substr(0,1);
    }

    // DisplayName is First + LastName with a space in between
    if((item.displayName as string).indexOf(' ') > -1) {
      _initials = (item.displayName as string).split(' ')[0].substr(0,1) + (item.displayName as string).split(' ')[1].substr(0,1);
    }

    return _initials.toUpperCase();
  }

  const onFilterChanged = (
    filter: string,
    selectedItems: IPersonaProps[] | undefined,
  ): IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
    let personas: IPersonaProps[] = [];
    if (filter) {
        return DataLayer.getPartialUsers(filter,GraphToken).then((response: []) => {
          response.forEach((itm:any, idx:number) => {
            if((itm.userPrincipalName as string).toLowerCase().indexOf('#ext#') === -1) {
              personas.push({
                text: itm.displayName,
                title: itm.displayName,
                imageInitials: getInitialsFromPerson(itm),
                secondaryText: itm.jobTitle,
                id: itm.id,
                itemProp: itm.userPrincipalName
              });
            }
          });
          return personas;
        });
    } else {
      return [];
    }
  };

  const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
      return personasToReturn;
  };

  const returnMostRecentlyUsed = (currentPersonas: IPersonaProps[] | undefined): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (currentPersonas !== undefined) {
      return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
    } else {
      return [];
    }
  };

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = peopleList.indexOf(item);
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = peopleList
        .slice(0, indexPeopleList)
        .concat(peopleList.slice(indexPeopleList + 1));
      setPeopleList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  }; 

  return (
    <div>
      <Label htmlFor="PeoplePicker" required={props.mandatory}>{props.title}</Label>
      <NormalPeoplePicker
        selectedItems={props.selectedUsers}
        onChange={props.onChangeValue}
        itemLimit={props.maxItems}
        // eslint-disable-next-line react/jsx-no-bind
        onResolveSuggestions={onFilterChanged}
        // eslint-disable-next-line react/jsx-no-bind
        onEmptyInputFocus={returnMostRecentlyUsed}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        className={'ms-PeoplePicker'}
        key={'normal'}
        // eslint-disable-next-line react/jsx-no-bind
        onRemoveSuggestion={onRemoveSuggestion}
        onValidateInput={validateInput}
        removeButtonAriaLabel={'Remove'}
        inputProps={{
          'aria-label': (props.title),
        }}
        styles={InnoStyles.peoplePickerStyles}
        componentRef={picker}
        onInputChange={onInputChange}
        resolveDelay={300}
      />
    </div>
  );
};

function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
  return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter(item => item.text === persona.text).length > 0;
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

/**
 * Takes in the picker input and modifies it in whichever way
 * the caller wants, i.e. parsing entries copied from Outlook (sample
 * input: "Aaron Reid <aaron>").
 *
 * @param input The text entered into the picker.
 */
function onInputChange(input: string): string {
  const outlookRegEx = /<.*>/g;
  const emailAddress = outlookRegEx.exec(input);

  if (emailAddress && emailAddress[0]) {
    return emailAddress[0].substring(1, emailAddress[0].length - 1);
  }

  return input;
}