/* eslint-disable import/no-anonymous-default-export */
import { dictionaryList } from './../languages';
export default (state: any, action: any) => {
  switch (action.type) {
    case 'UPDATE_AADTOKEN':
      return {
        ...state,
        AADToken: action.payload
      }
    case 'UPDATE_GRAPHTOKEN':
      return {
        ...state,
        GraphToken: action.payload
      }
    case 'ADD_TEMPLATE':
      return {
        ...state,
        templates: action.payload
      }
    case 'UPDATE_ORDERFORM':
      return {
        ...state,
        OrderForm: action.payload
      }
    case 'UPDATE_TENANTID':
      return {
        ...state,
        TenantID: action.payload
      }
    case 'UPDATE_FORMSEND':
      return {
        ...state,
        formSend: action.payload
      }
    case 'UPDATE_LANGUAGE':
      return {
        ...state,
        userLanguage: action.payload,
        languageDictionary: Object.entries(dictionaryList).filter(itm => itm[0] === action.payload)[0][1]
      }

    default:
      return state;
  }
}