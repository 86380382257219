import React, { Fragment, FunctionComponent, ReactElement, useCallback, useEffect, useRef, useState, useContext } from 'react';
import { Dropdown, DropdownMenuItemType, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { Link } from '@fluentui/react/lib/Link';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TextField } from '@fluentui/react/lib/TextField';
import { Toggle } from '@fluentui/react';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Modal } from '@fluentui/react/lib/Modal';
import { Dialog, DialogFooter } from '@fluentui/react/lib/Dialog';
import { Icon } from '@fluentui/react/lib/Icon';
import { useBoolean } from '@uifabric/react-hooks';
import * as DataLayer from '../DataLayer';
import * as CachedDataLayer from '../CachedDataLayer';
import { ITemplateProps } from './Template';
import TemplateSelection from './TemplateSelection';
import * as InnoStyles from '../styles';
import RWAGlobalContext from '../context/RWAGlobalContext';
import * as jwt from 'jsonwebtoken';
import { TMDropZone } from './TMDropZone';
import { TMChannelTree } from './TMChannelTree';
import { TMDnDBox } from './TMDnDBox';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TMDnDItemTypes } from './TMDnDItemTypes';
import { Label } from '@fluentui/react/lib/Label';
import { INav } from '@fluentui/react/lib/Nav';
import { ITMTemplateManagementProps, TMTemplateODataValues } from './TemplateInterfaces';
import TMMessageBox from './TMMessageBox';


const validText: RegExp = /^[A-Za-z0-9_-\s]+$/; // letters, numbers, underscore, minus, space (at least one character)

export enum Visibility {
  private = 'Private',
  public = 'Public'
}
export interface TMDnDBoxState {
  name: string
  type: string
  icon: string
  data: any
}

export const TMTemplateManagement: FunctionComponent<ITMTemplateManagementProps> = (props: ITMTemplateManagementProps): ReactElement => {

  // Global Context
  const { AADToken, languageDictionary, checkTokens, TenantID } = useContext(RWAGlobalContext);

  // Modal dialog
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  // Template selection
  const [templates, setTemplates] = useState<ITemplateProps[]>([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [selectedTemplateKey, setSelectedTemplateKey] = useState('');
  const [selectedDelTemplateKey, setSelectedDelTemplateKey] = useState('');
  const [createNewMode, setCreateNewMode] = useState(false);

  // Template fields
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionMultiline, setDescriptionMultiline] = useState(false);
  const [prefix, setPrefix] = useState('');
  const [suffix, setSuffix] = useState('');
  const [lifecycle, setLifecycle] = useState('');
  const [icon, setIcon] = useState('');
  const [templateJson, setTemplateJson] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  const tabFirstRender = useRef(true);
  const validationRequired = useRef(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [returnButtonText, setReturnButtonText] = useState(languageDictionary.TemplateManagement.ReturnBtnStateText);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showDeleteFailure, setShowDeleteFailure] = useState(false);

  // Drag&Drop definitions
  const [PropertiesDialogTitle, setPropertiesDialogTitle] = useState('');
  const [hideTabPropDialog, { toggle: toggleTabPropHideDialog }] = useBoolean(true);
  const [hideChannelAddDialog, { toggle: toggleChannelAddDialog }] = useBoolean(true);
  const [newChannelName, setNewChannelName] = useState('');
  const [currentSelectedChannel, setCurrentSelectedChannel] = useState<any>();
  const [dndDialogTabName, setDndDialogTabName] = useState('');
  const [dndDialogTabURL, setDndDialogTabURL] = useState('');
  const [currentDnDTabPropertiesItem, setCurrentDnDTabPropertiesItem] = useState<any>();
  const [showChannelSelectMessage, setShowChannelSelectMessage] = useState(false);
  const [DnDPropUrlErrorMsg, setDnDPropUrlErrorMsg] = useState('');
  const URLRegEx = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

  const dialogStyles = { main: { minWidth: 450 } };
  const [channelTabCollector, setChannelTabCollector] = useState<Array<any>>([]);
  const [selectedChannelTabs, setSelectedChannelTabs] = useState<Array<any>>([]);
  const TemplateTreeRef = useRef<INav>(null);
  const [urlEditDisabled, setUrlEditDisabled] = useState(true);

  const [showGeneralErrorMsg, setShowGeneralErrorMsg] = useState<boolean>(false);
  const [generalErrorMsg, setGeneralErrorMsg] = useState('');

  const [boxes] = useState<TMDnDBoxState[]>([
    //{ name: 'OneNote', type: TMDnDItemTypes.TAB, icon: "OneNoteLogo", data: { type: TMTemplateODataValues.ONENOTE } },
    { name: languageDictionary.TemplateManagement.DnDBoxPlannerText, type: TMDnDItemTypes.TAB, icon: "PlannerLogo", data: { type: TMTemplateODataValues.PLANNER } },
    { name: languageDictionary.TemplateManagement.DndBoxWebLinkText, type: TMDnDItemTypes.TAB, icon: "Link12", data: { type: TMTemplateODataValues.WEB } },
  ]);

  /**
   * Validate prefix or suffix
   * @param value prefix or suffix
   */
  const validatePrefixOrSuffix = (value: string): boolean => {
    if (value.length === 0) { // can be empty
      return true;
    }
    if (!validText.test(value.trim())) {
      return false;
    }
    if (value.length > 2 && value.length < 16) { // must be between 3 and 15 characters long
      return true;
    } else {
      return false;
    }
  }

  /**
   * Handle template deletion
   * Opens the Modal dialog for confirmation
   * @param templateKey key of the selected template to delete
   */
  const handleTemplateDeletion = (templateKey: string): void => {
    setSelectedDelTemplateKey(templateKey);
    showModal();
  };

  /**
   * Template deletion has been confirmed, ready to execute.
   */
  const onConfirmedDeleteTemplate = () => {
    hideModal();
    const template: ITemplateProps = templates.filter(template => template.key.toString().indexOf(selectedDelTemplateKey) > -1)[0];
    DataLayer.deleteTemplate({ "TemplateId": template.key }, AADToken, TenantID)
      .then((statusCode) => {
        if (statusCode === 200) {
          retrieveTemplates();
          setSaving(false);
          setSaveEnabled(false);
          setShowDeleteSuccess(true);
        }
      }).catch(error => {
        console.error('Error deleting template', error);
        setShowDeleteFailure(true);
      });
  }

  /**
   * Handle template selection
   * @param templateKey key of the selected template
   */
  const handleTemplateSelection = (templateKey: string): void => {
    setSaveEnabled(false);
    setReturnButtonText(languageDictionary.TemplateManagement.CancelBtnText);
    validationRequired.current = false;
    setShowSaveSuccess(false);
    setSelectedTemplateKey(templateKey);
    const template: ITemplateProps = templates.filter(template => template.key.toString().indexOf(templateKey) > -1)[0];
    const templateJsonParsed = JSON.parse(template.templateJson);
    let privacy: boolean = (templateJsonParsed.visibility === Visibility.private);

    setName(template.name);
    setDescription(template.description);
    setPrefix(template.namingPolicy.prefix);
    setSuffix(template.namingPolicy.suffix);
    setLifecycle(template.lifecycle);
    setIcon(template.icon);
    setTemplateJson(template.templateJson);
    setIsPrivate(privacy);
    setChannelTabCollector(templateJsonParsed.channels);
  }

  /**
   * Handle create new mode initialization
   */
  const handleCreateNewModeInit = (): void => {
    setReturnButtonText(languageDictionary.TemplateManagement.CancelBtnText);
    const defaultTemplateJson: string = `{   "channels": [     {       "description": "",       "displayName": "Meetings",       "isFavoriteByDefault": true     },     {       "description": "",       "displayName": "Virtual Coffee",       "isFavoriteByDefault": true     }   ],   "description": "#DESCRIPTION#",   "discoverySettings": {     "showInTeamsSearchAndSuggestions": true   },   "displayName": "#NAME#",   "funSettings": {     "allowCustomMemes": true,     "allowGiphy": true,     "allowStickersAndMemes": true,     "giphyContentRating": "Moderate"   },   "guestSettings": {     "allowCreateUpdateChannels": false,     "allowDeleteChannels": false   },   "memberSettings": {     "allowAddRemoveApps": true,     "allowCreateUpdateChannels": true,     "allowCreateUpdateRemoveConnectors": true,     "allowCreateUpdateRemoveTabs": true,     "allowDeleteChannels": true   },   "messagingSettings": {     "allowChannelMentions": true,     "allowOwnerDeleteMessages": true,     "allowTeamMentions": true,     "allowUserDeleteMessages": true,     "allowUserEditMessages": true   },   "owners@odata.bind": [     "#OWNER#"   ],   "template@odata.bind": "https://graph.microsoft.com/v1.0/teamsTemplates('standard')",   "visibility": "#PRIVACY#" }`;
    setTemplateJson(defaultTemplateJson);
    setCreateNewMode(true);
  }

  /**
   * Handle successful save
   */
  const handleSaveSuccess = (): void => {
    retrieveTemplates();
    setReturnButtonText(languageDictionary.TemplateManagement.ReturnBtnBackText);
    setSaving(false);
    setSaveEnabled(false);
    setShowSaveSuccess(true);
  }

  /**
   * Send edit template or create new template request to the API
   */
  const handleSaveClick = (): void => {
    setSaving(true);
    const payload: ITemplateProps = {
      key: createNewMode ? '' : selectedTemplateKey,
      name: name,
      description: description,
      namingPolicy: {
        prefix: prefix,
        suffix: suffix
      },
      lifecycle: lifecycle,
      icon: icon,
      templateJson: buildTemplateJson()
    };    

    // handleSaveSuccess();
    if (createNewMode) {
      DataLayer.createTemplate(payload, AADToken, TenantID)
        .then((statusCode) => {
          handleSaveSuccess();
          //console.log('create template response status code: ' + statusCode); // TODO: error handling in Logic App and UI
        })
        .catch((error) => {
          setSaving(false);
          console.error(error);
        });
    } else {
      DataLayer.editTemplate(payload, AADToken, TenantID)
        .then((statusCode) => {
          handleSaveSuccess();
          //console.log('edit template response status code: ' + statusCode); // TODO: error handling in Logic App and UI
        })
        .catch((error) => {
          setSaving(false);
          console.error(error);
        });
    }
  }

  const buildTemplateJson = (): string => {
    let templ = JSON.parse(templateJson);
    templ.channels = channelTabCollector;
    templ.visibility = (isPrivate ? Visibility.private : Visibility.public);

    let newObj = JSON.stringify(templ).replace(/"iconName":".*?"/gm, "").replace(/},}/gm, "}}").replace(/'/g, "''");
    //let newObj = JSON.stringify(templ).replace(/'/g, "''");

    return newObj;
  };

  /**
   * Retrieve templates into state
   */
  const retrieveTemplates = useCallback(async () => {
    setTemplatesLoading(true);
    const decoded: { [key: string]: any; } = jwt.decode(AADToken) as { [key: string]: any; };
    let allTemplates: ITemplateProps[] = [];
    try {
      allTemplates = await CachedDataLayer.getCachedAllTemplates(AADToken, decoded.tid);
      setTemplates(allTemplates);
      setTemplatesLoading(false);
    } catch (e: any) {
      setGeneralErrorMsg(e);
      setShowGeneralErrorMsg(true);
      setTemplatesLoading(false);
    }
  }, [AADToken]);

  /**
   * Handle return to template selection
   * @param refreshTemplates whether to refresh templates
   */
  const handleReturnToSelection = useCallback((refreshTemplates: boolean): void => {
    if (refreshTemplates) {
      retrieveTemplates();
    }
    setSaveEnabled(false);
    validationRequired.current = false;
    setShowSaveSuccess(false);
    setSelectedTemplateKey('');
    setCreateNewMode(false);
    setName('');
    setDescription('');
    setPrefix('');
    setSuffix('');
    setLifecycle('');
    setIcon('');
    setTemplateJson('');
    setIsPrivate(false);
    setChannelTabCollector([]);
    setSelectedChannelTabs([]);
  }, [retrieveTemplates]);

  // Drag&Drop functions
  const handleDrop = (item: any) => {
    if (channelTabCollector.length === 0) return; // If there is no channel (when creating a new template for example) return and don't drop the item.
    if (currentSelectedChannel !== undefined) {
      let idxNr: number = channelTabCollector.findIndex(itm => itm.displayName === currentSelectedChannel.name);
      let tmpCol = channelTabCollector;
      if (tmpCol[idxNr]['tabs'] !== undefined) {
        tmpCol[idxNr].tabs.push({
          "teamsApp@odata.bind": "https://graph.microsoft.com/v1.0/appCatalogs/teamsApps('" + item.data.type + "')",
          "name": item.name,
          "configuration": {
            "contentUrl": ""
          },
          "iconName": item.icon
        });
        setChannelTabCollector(tmpCol);
        setSelectedChannelTabs(tmpCol[idxNr]['tabs']);
        setSaveEnabled(true);
      } else {
        tmpCol[idxNr]['tabs'] = [
          {
            "teamsApp@odata.bind": "https://graph.microsoft.com/v1.0/appCatalogs/teamsApps('" + item.data.type + "')",
            "name": item.name,
            "configuration": {
              "contentUrl": ""
            },
            "iconName": item.icon
          }
        ];
        setChannelTabCollector(tmpCol);
        setSelectedChannelTabs(tmpCol[idxNr].tabs);
        setSaveEnabled(true);
      }
    } else {
      setShowChannelSelectMessage(true);
      setTimeout(() => { setShowChannelSelectMessage(false) }, 5010);
      setSaveEnabled(false);
      //console.log('no channel on the left selected');
    }
  }

  const handleTreeChannelChange = (item: any) => {
    // console.log('tree channel change', item);
    if (item !== undefined) {
      setCurrentSelectedChannel(item);
      let idxNr: number = channelTabCollector.findIndex(itm => itm.displayName === item.name); // Number.parseInt(item.key.slice(-1)) - 1;
      if (channelTabCollector[idxNr] !== undefined) {
        if (channelTabCollector[idxNr].tabs !== undefined) {
          setSelectedChannelTabs(channelTabCollector[idxNr].tabs);
        } else {
          setSelectedChannelTabs([]);
        }
      }
    }
  };

  const handleDnDPropertiesClick = (item: any) => {
    setSaveEnabled(true);
    setCurrentDnDTabPropertiesItem(item);
    setPropertiesDialogTitle(item.name + languageDictionary.TemplateManagement.DnDBoxPropertiesText);
    setDndDialogTabName(item.name);
    setDndDialogTabURL(item.configuration.contentUrl);
    if (item['teamsApp@odata.bind'].indexOf(TMTemplateODataValues.WEB) > -1) {
      setUrlEditDisabled(false);
    } else { setUrlEditDisabled(true); }
    toggleTabPropHideDialog();
  }

  const isDropped = (boxName: string) => {
    return boxes.findIndex(itm => itm.name === boxName) > -1
  }

  const onAddChannel = () => {
    setSaveEnabled(true);
    toggleChannelAddDialog();
    setChannelTabCollector([...channelTabCollector, {
      description: "",
      displayName: newChannelName,
      isFavoriteByDefault: false
    }]);
    setNewChannelName('');
  };

  const newChannelNameOnChange = (val: any) => {
    setNewChannelName(val);
  }

  const onDnDTabDialogNameChange = (val: string) => {
    setDndDialogTabName(val);
  };

  const onDNDTabDialogURLChange = (val: string) => {
    setDndDialogTabURL(val);
  };

  const onDnDTabDialogSaveClicked = () => {
    if (!URLRegEx.test('https://' + dndDialogTabURL)) {
      setDnDPropUrlErrorMsg('Invalid Url');
      return;
    } else {
      setDnDPropUrlErrorMsg('');
    }
    let tmpChan = channelTabCollector;
    let idx = tmpChan.findIndex(itm => itm.displayName === currentSelectedChannel.name);
    let tIdx: number = 0;
    tmpChan[idx]['tabs'].forEach((itm: any, ix: number) => {
      if (itm['name'] === currentDnDTabPropertiesItem.name) {
        tIdx = ix;
      }
    });
    tmpChan[idx]['tabs'][tIdx].name = dndDialogTabName;
    tmpChan[idx]['tabs'][tIdx].configuration.contentUrl = dndDialogTabURL;
    setChannelTabCollector(tmpChan);
    toggleTabPropHideDialog();
  };

  const onDndTabDialogRemoveClicked = () => {
    let tmpChan = channelTabCollector;
    let idx = tmpChan.findIndex(itm => itm.displayName === currentSelectedChannel.name);
    const newTabList = tmpChan[idx]['tabs'].filter((itm: any) => itm.name !== dndDialogTabName);
    tmpChan[idx]['tabs'] = newTabList;
    setChannelTabCollector(tmpChan);
    setSelectedChannelTabs(newTabList);
    toggleTabPropHideDialog();
  }

  const onDnDChannelRemove = () => {
    let idx = channelTabCollector.findIndex(itm => itm.displayName === currentSelectedChannel.name);
    const remove = (items: any, index: number) => {
      return [...items.slice(0, index),
      ...items.slice(index + 1, items.length)];
    };
    setSelectedChannelTabs([]);
    setChannelTabCollector(remove(channelTabCollector, idx));
    setSaveEnabled(true);
  };

  /**
   * Retrieve templates into state on component mount
   */
  useEffect(() => {
    retrieveTemplates();
  }, [retrieveTemplates]);

  /**
   * Return to template selection when tab has been clicked
   */
  useEffect(() => {
    if (tabFirstRender.current) { // no need to handle tab click during first render of the component
      tabFirstRender.current = false;
      return;
    }
    handleReturnToSelection(true);
  }, [props.tabClick, handleReturnToSelection]);

  /**
   * Set description to multiline if necessary
   */
  useEffect(() => {
    if (description.length > 50) {
      setDescriptionMultiline(true);
    } else {
      setDescriptionMultiline(false);
    }
  }, [description]);

  /**
   * Validate form after user has changed a field value and its state has been updated
   */
  useEffect(() => {
    if (!validationRequired.current) {
      validationRequired.current = true;
      return;
    }
    const validDescription: RegExp = /^[A-Za-z0-9.,!_-\s]+$/; // description is less restrictive for now
    const validLifecycles: string[] = ['3', '6', '12', '24', '36'];
    const valid: boolean =
      validText.test(name.trim()) &&
      validDescription.test(description.trim()) &&
      validatePrefixOrSuffix(prefix) &&
      validatePrefixOrSuffix(suffix) &&
      validLifecycles.indexOf(lifecycle) > -1 &&
      validText.test(icon.trim()) &&
      templateJson.trim().length > 0;
    if (valid) {
      setSaveEnabled(true);
    } else {
      setSaveEnabled(false);
    }
  }, [name, description, prefix, suffix, lifecycle, icon, templateJson]);


  useEffect(() => {
    const ct = async () => {
      await checkTokens();
    }
    ct();
  });

  return (
    <Fragment>
      <TMMessageBox
        messageType={MessageBarType.error}
        visible={showGeneralErrorMsg}
        message={generalErrorMsg}
        timeout={5000}
      />
      <Modal
        titleAriaId='ModalTitleId'
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={true}
        styles={InnoStyles.modalDialogStyle}
        containerClassName={InnoStyles.TemplateListClassNames.modalDialog}
      >
        <Stack>
          <StackItem styles={InnoStyles.modalContentStyle}>
            <Text as="strong" variant="large">{languageDictionary.TemplateManagement.DeleteTemplateText}</Text>
          </StackItem>
          <StackItem styles={InnoStyles.modalFooterStyle}>
            <PrimaryButton id="OrderBtn" text={languageDictionary.TemplateManagement.DeleteTemplateModalBtnOkText} styles={InnoStyles.primaryButtonStyles} onClick={onConfirmedDeleteTemplate} />&nbsp;&nbsp;
            <PrimaryButton id="OrderBtn" text={languageDictionary.TemplateManagement.DeleteTemplateModalBtnCancelText} styles={InnoStyles.primaryButtonStyles} onClick={hideModal} />
          </StackItem>
        </Stack>
      </Modal>
      <Dialog
        hidden={hideTabPropDialog}
        onDismiss={toggleTabPropHideDialog}
        title={PropertiesDialogTitle}
        closeButtonAriaLabel={languageDictionary.TemplateManagement.CloseBtnText}
        styles={dialogStyles}
      >
        <TextField label="Name" onChange={(event) => onDnDTabDialogNameChange((event.target as HTMLInputElement).value)} value={dndDialogTabName}></TextField>
        <TextField label="URL" onChange={(event) => onDNDTabDialogURLChange((event.target as HTMLInputElement).value)} prefix="https://" value={dndDialogTabURL} disabled={urlEditDisabled} errorMessage={DnDPropUrlErrorMsg} ></TextField>
        <DialogFooter>
          <PrimaryButton onClick={onDnDTabDialogSaveClicked} text={languageDictionary.TemplateManagement.SaveBtnText} />
          <DefaultButton onClick={onDndTabDialogRemoveClicked} text={languageDictionary.TemplateManagement.RemoveBtnText} />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideChannelAddDialog}
        title={languageDictionary.TemplateManagement.CreateChannelDialogTitleText}
        onDismiss={toggleChannelAddDialog}
        styles={dialogStyles}
      >
        <TextField label={languageDictionary.TemplateManagement.ChannelNameText} onChange={(event) => newChannelNameOnChange((event.target as HTMLInputElement).value)}></TextField>
        <DialogFooter>
          <DefaultButton onClick={toggleChannelAddDialog} text={languageDictionary.TemplateManagement.CancelBtnText} />
          <PrimaryButton onClick={onAddChannel} text={languageDictionary.TemplateManagement.SaveBtnText} />
        </DialogFooter>
      </Dialog>
      <Text as="h2" variant="mediumPlus" styles={InnoStyles.h2Style}>
        {selectedTemplateKey ? (languageDictionary.TemplateManagement.EditingTeamTemplateText + name) : createNewMode ? languageDictionary.TemplateManagement.CreateNewTeamTemplateText : languageDictionary.TemplateManagement.SelectTeamTemplateEditText}
      </Text>
      {!selectedTemplateKey && !createNewMode && !templatesLoading &&
        <Fragment>
          <TemplateSelection
            managementMode={true}
            templates={templates}
            visibleUntilScroll={7}
            onSelectTemplate={handleTemplateSelection}
            onDeleteTemplate={handleTemplateDeletion}
            id='FilterInputBox'
          />
          <Stack>
            <StackItem styles={InnoStyles.createNewTemplateButtonStyles}>
              <PrimaryButton
                onClick={handleCreateNewModeInit}
                styles={InnoStyles.primaryButtonStyles}
                text={languageDictionary.TemplateManagement.CreateNewTemplateText}>
              </PrimaryButton>
            </StackItem>
          </Stack>
        </Fragment>
      }
      {!selectedTemplateKey && !createNewMode && templatesLoading && (<Spinner size={SpinnerSize.large} label={languageDictionary.TemplateManagement.SpinnerLabelText} />)}
      {(selectedTemplateKey || createNewMode) && <Stack as="fieldset" styles={InnoStyles.fieldSetStyles} role="form">
        <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <TextField
              label={languageDictionary.TemplateManagement.TemplateNameText}
              onChange={(event) => setName((event.target as HTMLInputElement).value)}
              required
              value={name}>
            </TextField>
          </StackItem>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <TextField
              label={languageDictionary.TemplateManagement.TemplateDescriptionText}
              multiline={descriptionMultiline}
              onChange={(event) => setDescription((event.target as HTMLInputElement).value)}
              required
              value={description}>
            </TextField>
          </StackItem>
        </Stack>
        <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <TextField
              label={languageDictionary.TemplateManagement.TeamNamePrefixText}
              onChange={(event) => setPrefix((event.target as HTMLInputElement).value)}
              value={prefix}>
            </TextField>
          </StackItem>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <TextField
              label={languageDictionary.TemplateManagement.TeamNameSuffixText}
              onChange={(event) => setSuffix((event.target as HTMLInputElement).value)}
              value={suffix}>
            </TextField>
          </StackItem>
        </Stack>
        <Stack tokens={InnoStyles.formStackTokens} styles={{ root: { paddingBottom: 0 } }} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <Dropdown
              label={languageDictionary.TemplateManagement.TeamLifecycleLabelText}
              title="TeamLifecycle"
              onChange={(_event, value) => setLifecycle((value as IDropdownOption).key as string)}
              options={[
                { key: 'months', text: languageDictionary.Months, itemType: DropdownMenuItemType.Header },
                { key: '3', text: languageDictionary.Option3Months },
                { key: '6', text: languageDictionary.Option6Months },
                { key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider },
                { key: 'years', text: languageDictionary.Years, itemType: DropdownMenuItemType.Header },
                { key: '12', text: languageDictionary.Option1Year },
                { key: '24', text: languageDictionary.Option2Years },
                { key: '36', text: languageDictionary.Option3Years }
              ]}
              placeholder={languageDictionary.SelectAnOption}
              required
              selectedKey={lifecycle}
            />
          </StackItem>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <TextField
              label={languageDictionary.TemplateManagement.TeamIconLabelText}
              onChange={(event) => setIcon((event.target as HTMLInputElement).value)}
              placeholder={languageDictionary.TemplateManagement.ForExampleIconText}
              required
              value={icon}>
            </TextField>
            <Text as="span" styles={InnoStyles.textStyle} variant="small">
              Select from <Link href="https://uifabricicons.azurewebsites.net/" target="_blank">Office UI Fabric Icons</Link>. Right click icon and copy friendly name.
            </Text>
          </StackItem>
        </Stack>
        <Stack tokens={InnoStyles.gapStackTokens} styles={{ root: { paddingRight: 10, paddingLeft: 10, paddingBottom: 10 } }} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
          <StackItem grow styles={InnoStyles.stackItemStyles} >
            <Toggle label={`Default ${languageDictionary.TeamCreation.TeamPrivacy}`} onText={languageDictionary.Private} offText={languageDictionary.Public} defaultChecked={isPrivate} onChange={(_event, value) => setIsPrivate(value as boolean)} />
          </StackItem>
        </Stack>
        <Stack styles={{ root: { backgroundColor: 'transparent' } }}>
          <DndProvider backend={HTML5Backend}>
            <Stack horizontal={!InnoStyles.isSmallMobileDevice.matches} tokens={InnoStyles.formStackTokens}>
              <StackItem grow styles={InnoStyles.channelTreeStyles}>
                <TMChannelTree
                  onNavLinkClicked={(item) => handleTreeChannelChange(item)}
                  key={1}
                  DropZoneName={name === '' ? languageDictionary.TemplateManagement.UnsavedTemplateText : name}
                  TMRef={TemplateTreeRef}
                  Channels={channelTabCollector}
                  onChannelAdd={toggleChannelAddDialog}
                  onChannelRemove={onDnDChannelRemove}
                />
              </StackItem>
              <StackItem grow styles={InnoStyles.dropZoneStackItemStyles} >
                <div style={{ width: '100%' }}>
                  <Label style={{ float: 'none' }}>{languageDictionary.TemplateManagement.DnDItemToChannelText}<Icon style={{ color: "rgb(202, 0, 144)", verticalAlign: "bottom" }} iconName="ChevronDownSmall"></Icon></Label>
                  <div style={{ overflow: 'hidden', clear: 'both', marginTop: 10, width: '100%' }}>
                    {boxes.map(({ name, type, data, icon }, index) => (
                      <TMDnDBox
                        name={name}
                        type={type}
                        icon={icon}
                        isDropped={isDropped(name)}
                        data={data}
                        key={index}
                      />
                    ))}
                  </div>
                </div>
                <DndProvider backend={HTML5Backend}>
                  <div>
                    <div style={{}}>
                      <TMDropZone
                        accept={[TMDnDItemTypes.TAB]}
                        onDrop={(item) => handleDrop(item)}
                        key={1}
                        content={selectedChannelTabs}
                        DropZoneName={languageDictionary.TemplateManagement.ChannelTabsText}
                        onPropertiesClick={handleDnDPropertiesClick}
                      />
                    </div>
                  </div>
                </DndProvider>
              </StackItem>
            </Stack>
          </DndProvider>
        </Stack>
        <Stack horizontal tokens={InnoStyles.formStackTokens}>
          <TMMessageBox
            messageType={MessageBarType.success}
            visible={showSaveSuccess}
            message={languageDictionary.TemplateManagement.TemplateSavedText}
            timeout={5000}
          />
          <TMMessageBox
            messageType={MessageBarType.warning}
            visible={showChannelSelectMessage}
            message='Please select a channel on the left before adding tabs'
            timeout={5000}
          />
          {showDeleteSuccess && (
            <MessageBar isMultiline={false} messageBarType={MessageBarType.success}>
              <Text as="p">{languageDictionary.TemplateManagement.TemplateRemovedText}</Text>
            </MessageBar>
          )}
          {showDeleteFailure && (
            <MessageBar isMultiline={false} messageBarType={MessageBarType.error}>
              <Text as="p">{languageDictionary.TemplateManagement.ErrorDeletingTemplateMsgText}</Text>
            </MessageBar>
          )}
        </Stack>
        <Stack horizontal tokens={InnoStyles.formStackTokens}>
          <StackItem>
            <PrimaryButton
              disabled={!saveEnabled || saving}
              onClick={handleSaveClick}
              styles={!saveEnabled || saving ? InnoStyles.primaryButtonDisabledStyles : InnoStyles.primaryButtonStyles}
              text={languageDictionary.TemplateManagement.SaveTemplateBtnText}
            >
            </PrimaryButton>
          </StackItem>

          {saving && (
            <StackItem styles={InnoStyles.verticallyCenteredStyles}>
              <Spinner size={SpinnerSize.medium} />
            </StackItem>
          )}

          {!saving && (
            <StackItem>
              <PrimaryButton text={returnButtonText} styles={InnoStyles.secondaryButtonStyles} onClick={() => handleReturnToSelection(false)}></PrimaryButton>
            </StackItem>
          )}
        </Stack>
      </Stack>}
    </Fragment>
  );
}
