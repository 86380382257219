import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { RWAGlobalContext } from '../context/RWAGlobalContext';
import CreationSummaryTable from './CreationSummaryTable';
import * as InnoStyles from '../styles';


const CreationSummary: FunctionComponent = (): ReactElement => {
  const { AADToken,  formSend, languageDictionary, OrderForm, updateOrderForm, updateFormSend } = useContext(RWAGlobalContext);
  const [newTeamClicked, setnewTeamClicked] = useState(false);

  let operations;

  if (!newTeamClicked) {
    operations = [{
      teamname: OrderForm?.teamName,
      teamdescription: OrderForm?.teamDescription,
      primaryowner: OrderForm?.selectedPrimaryOwners[0]['title'],
      secondaryowner: OrderForm?.selectedSecondaryOwners[0]['title'],
      lifecycle: OrderForm?.teamLifecycle.text,
      teamprivacy: OrderForm?.teamIsPrivate ? languageDictionary.Private : languageDictionary.Public,
      selectedtemplate: OrderForm?.selectedTemplateName,
    }];
  } else {
    operations = [{
      teamname: '',
      teamdescription: '',
      primaryowner: '',
      secondaryowner: '',
      lifecycle: [],
      teamprivacy: '',
      selectedtemplate: '',
    }];
  }

  const clickEventHome = (e: any) => {
    // Make sure we reset any form data before we redirect back to the form.
    updateFormSend(false);
    const newOrderForm = { selectedPrimaryOwners: [], selectedSecondaryOwners: [], selectedTemplateId: '', teamDescription: '', teamLifecycle: { key: '', text: '' }, teamName: '', teamIsPrivate: false };
    updateOrderForm(newOrderForm);

    // Now trigger redirect
    window.location.href = window.location.origin;
  }

  const clickEvent = (e: any) => {
    // Make sure we reset any form data before we redirect back to the form.
    updateFormSend(false);
    const newOrderForm = { selectedPrimaryOwners: [], selectedSecondaryOwners: [], selectedTemplateId: '', teamDescription: '', teamLifecycle: { key: '', text: '' }, teamName: '', teamIsPrivate: false };
    updateOrderForm(newOrderForm);

    // Now trigger redirect
    setnewTeamClicked(true);
  }

  return (
    <Stack>
      {newTeamClicked && <Redirect to="/createTeam" />}
      <StackItem>
        <Text as="h2" variant="medium" styles={InnoStyles.h2Style}>
          {languageDictionary.TeamCreation.Congratulations}
        </Text>
        <Text as="p" variant="medium" styles={InnoStyles.ingressStyle}></Text>
      </StackItem>
      <Stack>
        <StackItem>
          <Text as="span" variant="medium" styles={InnoStyles.textStyle}>
            {languageDictionary.TeamCreation.SummaryOfTeamDetails}:<br /><br />
          </Text>
        </StackItem>
        <StackItem>
          <CreationSummaryTable children={operations} />
        </StackItem>
        <StackItem>
          <Text as="h2" variant="medium" styles={InnoStyles.h2Style} color={InnoStyles.theme.semanticColors.errorText}>
            {languageDictionary.TeamCreation.PleaseNote}<br />
          </Text>
          <Text as="p" variant="medium" styles={InnoStyles.textStyle}>
            {languageDictionary.TeamCreation.NoteRow1}<br />
            {languageDictionary.TeamCreation.NoteRow2}<br />
            {languageDictionary.TeamCreation.NoteRow3}<br />
            {languageDictionary.TeamCreation.NoteRow4}<br /><br />
          </Text>
        </StackItem>
      </Stack>
      <Stack tokens={InnoStyles.sectionStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} wrap>
        <StackItem>
          <DefaultButton
            onClick={clickEventHome}
            text={languageDictionary.Home}
            styles={InnoStyles.secondaryButtonStyles}
            iconProps={{ iconName: 'Back', styles: { root: { color: 'white', marginRight: '-5px' } } }}>
          </DefaultButton>
        </StackItem>
        <StackItem>
          <DefaultButton
            onClick={clickEvent}
            text={languageDictionary.TeamCreation.CreateAnotherTeam}
            styles={InnoStyles.secondaryButtonStyles}>
          </DefaultButton>
        </StackItem>
      </Stack>
    </Stack>
  );
}

export default CreationSummary;
