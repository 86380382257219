import React, { FunctionComponent, ReactElement, useContext } from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { Icon } from '@fluentui/react/lib/Icon';
import { StackItem } from '@fluentui/react/lib/Stack';
import { Card } from '@uifabric/react-cards';
import { cardTokens, verticalCardStyles, cardTitleStyle, verticalCardSectionStylesForIcons, cardIconStyle, verticalCardStackItemStyles } from './../styles'
import { RWAGlobalContext } from './../context/RWAGlobalContext';

export interface ITMServiceCardProps {
    title: string;
    icon: string;
    customClickEvent?: any;
}

export const TMServiceCard: FunctionComponent<ITMServiceCardProps> = (props: ITMServiceCardProps): ReactElement => {

    const { languageDictionary } = useContext(RWAGlobalContext);

    return (
        <StackItem grow={1} styles={verticalCardStackItemStyles}>
            <Card role="button" tokens={cardTokens} onClick={props.customClickEvent} styles={verticalCardStyles} as="button">
                <Card.Section fill styles={verticalCardSectionStylesForIcons} verticalFill={true}>
                    <Icon iconName={props.icon} styles={cardIconStyle} />
                </Card.Section>
                <Card.Section>
                    <Text variant="large" as="strong" styles={cardTitleStyle}>
                        { Object.entries(languageDictionary).filter(entry => entry[0] === props.title)[0][1] as string }
                    </Text>
                </Card.Section>
            </Card>
        </StackItem>
    );
};