import React, { Fragment, FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import { Dropdown, DropdownMenuItemType, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { MessageBarType } from '@fluentui/react/lib/MessageBar';
import { PrimaryButton, IButtonProps } from '@fluentui/react/lib/Button';
import { Text } from '@fluentui/react/lib/Text';
import { TextField } from '@fluentui/react/lib/TextField';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { Icon } from '@fluentui/react/lib/Icon';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { DirectionalHint } from '@fluentui/react/lib/common/DirectionalHint';
import { useId, useBoolean } from '@uifabric/react-hooks';
import * as jwt from 'jsonwebtoken';
import moment from 'moment';
import * as DataLayer from '../DataLayer';
import * as CachedDataLayer from '../CachedDataLayer';
import { RWAGlobalContext } from './../context/RWAGlobalContext';
import TemplateSelection from './TemplateSelection';
import { ITemplateProps } from './Template';
import { RWAPeoplePicker } from './RWAPeoplePicker';
import TMMessageBox from './TMMessageBox';
import * as InnoStyles from '../styles';
import { Visibility } from './TMTemplateManagement';


export interface ICreationFormProps {
  userName?: string; // current user name
  teamNameCharLimit: number;
  teamDescriptionCharLimit: number;
};

const CreationForm: FunctionComponent<ICreationFormProps> = (props: ICreationFormProps): ReactElement => {

  // Context API
  const {  OrderForm, updateOrderForm, updateFormSend, AADToken, languageDictionary, checkTokens } = useContext(RWAGlobalContext);

  // Message
  const [messageBoxVisible, setMessageBoxVisible] = useState(false);
  const [messageBoxMsg, setMessageBoxMsg] = useState(<Fragment></Fragment>); // message to display
  const [messageBoxMsgType, setMessageBoxMsgType] = useState(MessageBarType.error);

  // Team templates
  const [templatesLoading, setTemplatesLoading] = useState(true);
  const [templates, setTemplates] = useState<ITemplateProps[]>([]); // team templates
  const [selectedTemplateKey, setSelectedTemplateKey] = useState('');
  const [selectedTemplateName, setSelectedTemplateName] = useState('');

  // Team details
  const [prefix, setPrefix] = useState('');
  const [suffix, setSuffix] = useState('');
  const [nameInput, setNameInput] = useState('');
  const [name, setName] = useState(''); // full team name
  const [nameTipVisible, setNameTipVisible] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionTipVisible, setDescriptionTipVisible] = useState(false);
  const [primaryOwners, setPrimaryOwners] = useState<IPersonaProps[]>([]);
  const [secondaryOwners, setSecondaryOwners] = useState<IPersonaProps[]>([]);
  const [lifecycle, setLifecycle] = useState({ key: '', text: '' });
  const [isPrivate, setIsPrivate] = useState(false);
  const [templateJson, setTemplateJson] = useState('');

  // Create team button
  const [checkRequired, setCheckRequired] = useState(true);
  const [createAnyway, setCreateAnyway] = useState(false);
  const [createButtonEnabled, setCreateButtonEnabled] = useState(false);
  const [createSpinnerVisible, setCreateSpinnerVisible] = useState(false);

  // DropDown LifeCycle
  const tooltipId = useId('tooltip');
  const [ddLifeCycleDisabled, setDDLifeCycleDisabled] = useState(false);
  const [defaultLifeCycleKey, setDefaultLifeCycleKey] = useState<string>('');
  const InfoHostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
  const lifecycleOptions: IDropdownOption[] = [
    { key: 'months', text: languageDictionary.Months, itemType: DropdownMenuItemType.Header },
    { key: '3', text: languageDictionary.Option3Months },
    { key: '6', text: languageDictionary.Option6Months },
    { key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'years', text: languageDictionary.Years, itemType: DropdownMenuItemType.Header },
    { key: '12', text: languageDictionary.Option1Year },
    { key: '24', text: languageDictionary.Option2Years },
    { key: '36', text: languageDictionary.Option3Years }
  ];

  // TeachingBubbleProps
  const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(true);
  const TBprimaryButtonProps: IButtonProps = {
    children: 'Ok',
    onClick: toggleTeachingBubbleVisible
  };

  /**
   * Return label for team name field
   */
  const getNameLabel = (): string => {
    if (prefix && suffix) {
      return languageDictionary.TeamCreation.TeamNamePrefixSuffix;
    }
    if (prefix) {
      return languageDictionary.TeamCreation.TeamNamePrefix;
    }
    if (suffix) {
      return languageDictionary.TeamCreation.TeamNameSuffix;
    }
    return languageDictionary.TeamCreation.TeamName;
  }

  const getLifeCycleDate = (lifecycledropdownoption: any): moment.Moment => {
    return moment().add(lifecycledropdownoption['key'], 'M')
  }

  const buildOwnerArrayFromPersonas = (newValue: any): string => {
    let _returnArray: string[] = [];
    newValue.forEach((itm: any) => {
      _returnArray.push(itm.itemProp);
    });
    return _returnArray.join(',');
  }

  const handleTemplateSelection = (templateKey: string): void => {
    const selectedTemplate: ITemplateProps = templates.filter(template => template.key.toString().indexOf(templateKey) > -1)[0];
    const parsedTemplateJson = JSON.parse(selectedTemplate.templateJson);

    // Parse new values
    const newPrefix: string = selectedTemplate.namingPolicy.prefix;
    const newSuffix: string = selectedTemplate.namingPolicy.suffix;
    const newNameInputMaxLength: number = props.teamNameCharLimit - newPrefix.length - newSuffix.length;
    const newNameInput: string = nameInput.substring(0, newNameInputMaxLength); // cut extra characters from the end if max length has been exceeded due to selected template
    const newName: string = newPrefix + newNameInput + newSuffix;
    const selectedDDOption: IDropdownOption = lifecycleOptions.filter(itm => itm.key === selectedTemplate.lifecycle)[0];
    const visibility: string = parsedTemplateJson.visibility;
    let privacy: boolean = (visibility === Visibility.private);

    // Update state
    setSelectedTemplateKey(templateKey);
    setSelectedTemplateName(selectedTemplate.name);
    setPrefix(newPrefix);
    setSuffix(newSuffix);
    setNameInput(newNameInput);
    setName(newName);
    setDefaultLifeCycleKey(selectedTemplate.lifecycle);
    setDDLifeCycleDisabled(true);
    setLifecycle({ key: selectedDDOption.key as string, text: selectedDDOption.text });
    setIsPrivate(privacy);
    setTemplateJson(selectedTemplate.templateJson);
  }

  /**
   * Handle name input change (update full team name)
   * @param newValue new input value
   */
  const handleNameInputChange = (newValue: string) => {
    setNameInput(newValue);
    const newName = prefix + newValue + suffix;
    setName(newName);
  }

  /**
   * Check existing team names. If those exist, show message to user. If not, create a team.
   */
  const handleCheckAndCreate = async () => {
    setCreateButtonEnabled(false);
    setCreateSpinnerVisible(true);
    const existingGroups: any[] = await DataLayer.searchGroups({
      'name': nameInput.replace(/[^a-zåäöæøüßA-ZÅÄÖÆØÜẞ0-9 ]/g, ' ') // keep only Nordic and German letters, numbers and whitespace when searching
    }, AADToken);
    const existingTeamNames: string[] = existingGroups?.filter(group => group.resourceProvisioningOptions.includes('Team')).map(team => team.displayName);
    if (existingTeamNames?.length > 0) {
      existingTeamNames.sort();
      const messageHTML: JSX.Element = <Fragment>{languageDictionary.TeamCreation.SimilarlyNamedTeamsExist}:<br />{existingTeamNames.map((teamName) => { return <span>{teamName}<br /></span> })}</Fragment>;
      setMessageBoxMsg(messageHTML);
      setMessageBoxMsgType(MessageBarType.warning);
      setMessageBoxVisible(true);
      setCreateAnyway(true);
      setCreateButtonEnabled(true);
      setCreateSpinnerVisible(false);
    } else {
      handleCreate();
    }
    setCheckRequired(false);
  }

  const buildTemplateJson = (): string => {
    let templ = JSON.parse(templateJson);
    templ.visibility = isPrivate ? Visibility.private : Visibility.public;
    templ.displayName = name;
    templ.description = description;

    let newObj = JSON.stringify(templ);

    return newObj;
  };

  /**
   * Create a team
   */
  const handleCreate = async (): Promise<void> => {
    setCreateButtonEnabled(false);
    setCreateSpinnerVisible(true);

    const responseCode = await DataLayer.createTeam({
      'templateId': selectedTemplateKey,
      'templateName': selectedTemplateName,
      'owners': [
        buildOwnerArrayFromPersonas(primaryOwners),
        buildOwnerArrayFromPersonas(secondaryOwners)
      ],
      'lifecycle': getLifeCycleDate(lifecycle),
      'orderer': props.userName,
      'templateJson': buildTemplateJson()
    }, AADToken);
    if (responseCode === 200) { // save team details to global context after successful creation request
      OrderForm.selectedTemplateName = selectedTemplateName;
      OrderForm.teamName = name;
      OrderForm.teamDescription = description;
      OrderForm.selectedPrimaryOwners = primaryOwners;
      OrderForm.selectedSecondaryOwners = secondaryOwners;
      OrderForm.teamLifecycle = lifecycle;
      OrderForm.teamIsPrivate = isPrivate;
      updateOrderForm(OrderForm);
      updateFormSend(true);
    } else {
      setMessageBoxMsg(<Fragment>Error in creating team: {responseCode}</Fragment>);
      setMessageBoxMsgType(MessageBarType.error);
      setMessageBoxVisible(true);
    }
    setCreateSpinnerVisible(false);
  }

  /**
   * Retrieve templates into state on component mount
   */
  useEffect(() => {
    const retrieveTemplates = async () => {
      setTemplatesLoading(true);
      setDDLifeCycleDisabled(false);
      await checkTokens();
      const decoded: { [key: string]: any; } = jwt.decode(AADToken) as { [key: string]: any; };
      CachedDataLayer.getCachedAllTemplates(AADToken, decoded.tid).then((allTemplates: ITemplateProps[]) => {
        setTemplates(allTemplates.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
        setTemplatesLoading(false);
      });
    }
    retrieveTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AADToken]);

  /**
   * Validate form
   */
  useEffect(() => {
    setCreateAnyway(false);
    setCheckRequired(true);
    setMessageBoxVisible(false);
    // Check for duplicate owners
    let duplicateOwners: boolean = false;
    let uniqueOwnerIds: string[] = [];
    const owners: IPersonaProps[] = primaryOwners.concat(secondaryOwners);
    for (let i = 0; i < owners.length; i++) {
      const ownerId: string | undefined = owners[i].id;
      if (ownerId) {
        if (uniqueOwnerIds.includes(ownerId)) {
          duplicateOwners = true;
          break;
        } else {
          uniqueOwnerIds.push(ownerId);
        }
      }
    }

    const valid: boolean =
      selectedTemplateKey.trim().length > 0 &&
      nameInput.trim().length > 0 &&
      description.trim().length > 0 &&
      primaryOwners.length > 0 &&
      secondaryOwners.length > 0 &&
      !duplicateOwners &&
      lifecycle.text.length > 0;
    if (valid) {
      setCreateButtonEnabled(true);
    } else {
      setCreateButtonEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateKey, nameInput, description, primaryOwners, secondaryOwners, lifecycle]);

  return (
    <Fragment>
      <Text as="h2" variant="mediumPlus" id='FilterInputBox' styles={InnoStyles.h2Style}>
        {languageDictionary.TeamCreation.SelectTeamTemplate}
      </Text>
      {!templatesLoading &&
        <TemplateSelection
          managementMode={false}
          onDeleteTemplate={() => { }}
          onSelectTemplate={handleTemplateSelection}
          templates={templates}
          visibleUntilScroll={4}
          id='FilterBox'
        >
        </TemplateSelection>
      }
      {teachingBubbleVisible &&
        <TeachingBubble
          hasCloseButton={true}
          hasCondensedHeadline={true}
          headline={languageDictionary.TeamCreation.TeachingBubbleHeadline}
          onDismiss={toggleTeachingBubbleVisible}
          primaryButtonProps={TBprimaryButtonProps}
          target="#FilterInputBox">
          {languageDictionary.TeamCreation.TeachingBubbleMaintext}
        </TeachingBubble>
      }
      {templatesLoading && (<Spinner size={SpinnerSize.large} label={languageDictionary.TeamCreation.Spinner} />)}
      <Stack as="fieldset" styles={InnoStyles.fieldSetStyles} role="form">
        <Text as="legend" styles={InnoStyles.legendStyles} variant="large">{languageDictionary.TeamCreation.TeamDetails}</Text>
        <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <TextField
              autoComplete="off"
              description={nameTipVisible ? (props.teamNameCharLimit - name.length).toString() + ` ${languageDictionary.TeamCreation.CharactersLeft}` : ''}
              label={getNameLabel()}
              maxLength={props.teamNameCharLimit - (prefix?.length ?? 0) - (suffix?.length ?? 0)}
              onBlur={() => setNameTipVisible(false)}
              onChange={(event) => handleNameInputChange((event.target as HTMLInputElement).value)}
              onFocus={() => setNameTipVisible(true)}
              required
              styles={InnoStyles.OrderFormTeamNameFieldStyles}
              value={nameInput}>
            </TextField>
            {(prefix || suffix) &&
              <Fragment>
                <Text styles={InnoStyles.OrderFormTeamNameResultLabelStyles}>{languageDictionary.TeamCreation.TeamName}: </Text>
                <Text styles={InnoStyles.OrderFormTeamNameResultStyles}>{name}</Text>
              </Fragment>
            }
          </StackItem>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <TextField
              autoComplete="off"
              description={descriptionTipVisible ? (props.teamDescriptionCharLimit - description.length).toString() + ` ${languageDictionary.TeamCreation.CharactersLeft}` : ''}
              label={languageDictionary.TeamCreation.TeamDescription}
              maxLength={props.teamDescriptionCharLimit}
              multiline
              onBlur={() => setDescriptionTipVisible(false)}
              onChange={(event) => setDescription((event.target as HTMLInputElement).value)}
              onFocus={() => setDescriptionTipVisible(true)}
              required
              rows={4}
              styles={InnoStyles.OrderFormTeamDescriptionFieldStyles}
              value={description}>
            </TextField>
          </StackItem>
        </Stack>
        <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <RWAPeoplePicker title={languageDictionary.TeamCreation.TeamPrimaryOwner} mandatory={true} onChangeValue={(users: any) => setPrimaryOwners(users)} selectedUsers={primaryOwners} maxItems={1}></RWAPeoplePicker>
          </StackItem>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <RWAPeoplePicker title={languageDictionary.TeamCreation.TeamSecondaryOwner} mandatory={true} onChangeValue={(users: any) => setSecondaryOwners(users)} selectedUsers={secondaryOwners} maxItems={1}></RWAPeoplePicker>
          </StackItem>
        </Stack>
        <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
          <StackItem grow styles={InnoStyles.stackItemStyles}>
            <Dropdown
              placeholder={languageDictionary.SelectAnOption}
              styles={{ root: { display: 'inline-block' } }}
              label={languageDictionary.TeamCreation.TeamLifecycle}
              options={lifecycleOptions}
              disabled={ddLifeCycleDisabled}
              selectedKey={defaultLifeCycleKey}>
            </Dropdown>
            {ddLifeCycleDisabled && (
              <TooltipHost
                content={languageDictionary.TeamCreation.TeamLifecycleTooltip}
                id={tooltipId}
                calloutProps={{ gapSpace: 0 }}
                styles={InfoHostStyles}
                directionalHint={DirectionalHint.bottomCenter}>
                <Icon iconName='Info' aria-describedby={tooltipId} styles={InnoStyles.tooltipIconstyle} />
              </TooltipHost>
            )}
          </StackItem>
          <StackItem>
            <Toggle label={languageDictionary.TeamCreation.TeamPrivacy} onText={languageDictionary.Private} offText={languageDictionary.Public} checked={isPrivate} onChange={(_event, value) => setIsPrivate(value as boolean)} />
          </StackItem>
        </Stack>
        <Stack horizontal tokens={InnoStyles.formStackTokens}>
          <StackItem>
            <PrimaryButton
              disabled={!createButtonEnabled}
              onClick={checkRequired ? handleCheckAndCreate : handleCreate}
              styles={createButtonEnabled ? InnoStyles.primaryButtonStyles : InnoStyles.primaryButtonDisabledStyles}
              text={createAnyway ? languageDictionary.TeamCreation.CreateTeamAnyway : languageDictionary.TeamCreation.CreateTeam}>
            </PrimaryButton>
          </StackItem>
          <StackItem styles={InnoStyles.verticallyCenteredStyles}>
            {createSpinnerVisible && (<Spinner size={SpinnerSize.medium} />)}
          </StackItem>
        </Stack>
      </Stack>
      <TMMessageBox messageHTML={messageBoxMsg} messageType={messageBoxMsgType} visible={messageBoxVisible} />
    </Fragment>
  );
}

export default CreationForm;
