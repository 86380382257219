export enum TMTemplateODataValues {
    PLANNER = 'com.microsoft.teamspace.tab.planner',
    ONENOTE = '0d820ecd-def2-4297-adad-78056cde7c78',
    WEB = 'com.microsoft.teamspace.tab.web'
};

export interface TMTemplateODataTypes {
    Planner: TMTemplateODataValues.PLANNER,
    OneNote: TMTemplateODataValues.ONENOTE,
    Web: TMTemplateODataValues.WEB
};

export interface ITMTemplateManagementProps {
    tabClick: boolean; // value change indicates tab click
};