/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { AuthenticationState, AzureAD, IAccountInfo, IAzureADFunctionProps } from 'react-aad-msal';
import * as jwt from 'jsonwebtoken';
import { Text } from '@fluentui/react/lib/Text';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { authProviderAAD } from './authProvider/authProviderAAD';
import * as DataLayer from './DataLayer';
import RWAGlobalContext from './context/RWAGlobalContext';
import TMGlobalNavigation from './components/TMGlobalNavigation';
import WelcomeScreen from './components/WelcomeScreen';
import * as InnoStyles from './styles';
import { useHistory } from 'react-router';
//import TMFooter from './components/TMFooter';


export interface IUserAccountInfo {
  name?: string;
  email?: string;
  isAdmin: boolean;
}

const App: FunctionComponent = (): ReactElement => {

  const { formSend, isLocalDevEnv, updateAADToken, updateGraphToken, userLanguageChange, userLanguage, GraphToken, updateTenantID } = useContext(RWAGlobalContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userAccountInfo, setUserAccountInfo] = useState<IUserAccountInfo>();
  const [subEntityId, setSubEntityId] = useState<string>('')
  let his = useHistory();

  /**
   * Update Azure AD token and Graph token to global context
   * @param accountInfo user account info from MSAL
   */
  const updateTokensToGlobalContext = async (accountInfo: IAccountInfo) => {
    if (accountInfo?.jwtAccessToken?.length > 0) {
      const decoded: { [key: string]: any; } = jwt.decode(accountInfo.jwtAccessToken) as { [key: string]: any; };
      await updateTenantID(decoded.tid);
      updateAADToken(accountInfo.jwtAccessToken);
      const graphToken = await DataLayer.getGraphToken(accountInfo.jwtAccessToken, isLocalDevEnv);
      updateGraphToken(graphToken);
    }
  }

  useEffect(() => {
    const lang = async () => {
      const l = await DataLayer.getTMSettings();
      if(l !== undefined) {
        if(l.language !== userLanguage) {
          userLanguageChange(l.language);
        }
      }
    }
    lang();
  }, []);

  useEffect(() => {
    if (isLocalDevEnv) {
      console.log('local development environment');
      return;
    }

    const TMAuthorize = async () => {
      microsoftTeams.initialize(() => {
        microsoftTeams.getContext((context) => {
            microsoftTeams.authentication.getAuthToken({
                successCallback: (token: string) => {
                    const decoded: { [key: string]: any; } = jwt.decode(token) as { [key: string]: any; };
                    updateAADToken(token);
                    (async () => {
                      await updateTenantID(decoded.tid);
                      if(GraphToken === undefined || GraphToken === '') {
                        const gToken = await DataLayer.getGraphToken(token);
                        if(gToken) { updateGraphToken(gToken); }
                      }
                      const _admins = await DataLayer.getAllAdmins(token) as Array<any>; // should probably be changed so that normal user wouldn't need to query admins
                      if(_admins !== undefined) {
                        if(_admins.filter(person => person.UserEmail.toLowerCase() === decoded.upn.toLowerCase()).length === 1) { 
                          setUserAccountInfo({ name: decoded.name, email: decoded.upn, isAdmin: true });
                        } else {
                          setUserAccountInfo({ name: decoded.name, email: decoded.upn, isAdmin: false });
                        }
                      } else {
                        setUserAccountInfo({ name: decoded.name, email: decoded.upn, isAdmin: false });
                      }
                      if(decoded) {
                        setIsAuthenticated(true);
                        microsoftTeams.appInitialization.notifySuccess();
                      }
                    })();
                },
                failureCallback: (message: string) => {
                  setIsAuthenticated(false);
                  microsoftTeams.appInitialization.notifyFailure({
                      reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
                      message
                  });
                  console.error('Microsoft Teams context not detected. Please run TeamsMate only on Microsoft Teams!');
                },
                resources: [process.env.REACT_APP_SCOPE_AAD_API as string],
                silent: true
            });            

            if(context?.subEntityId) setSubEntityId(context.subEntityId as string)
        });
      });
    }
    TMAuthorize();
  }, [isLocalDevEnv]);

/*
      <Text as="h1" styles={InnoStyles.h1Style} className="title" variant="xxLarge" onClick={() => { window.location.href = '/'; }} >
        TeamsMate
      </Text><Link 
        to='/'
        style={{
          fontWeight: 'bold',
          color: 'rgb(0, 67, 168)',
          marginBottom: 5,
          cursor: 'pointer',
          fontFamily: "Segoe UI",
          textDecoration: 'none',
          fontSize: 26
        }}
        className='title'
      >TeamsMate</Link>
      */
  const navigateHome = () => {
    his.push('/', { Home: true })
  }

  return (
    <Stack>
      <Text as="h1" styles={InnoStyles.h1Style} className="title" variant="xxLarge" onClick={navigateHome} >
        TeamsMate
      </Text>
      {isLocalDevEnv
        ?
        // Local development environment
        <AzureAD provider={authProviderAAD} forceLogin={true} accountInfoCallback={updateTokensToGlobalContext}>
          {({accountInfo, authenticationState, error}: IAzureADFunctionProps) => {
            let isAdmin: boolean = false;
            if (authenticationState === AuthenticationState.Authenticated) {
              // Determine if user is admin
              if (accountInfo?.account['idTokenClaims'].roles !== undefined) {
                if (accountInfo.account['idTokenClaims'].roles[0].toLowerCase() === 'isadmin') {
                  isAdmin = true;
                }
              }
            }
            return (
              <Fragment>
                {authenticationState === AuthenticationState.Unauthenticated && !formSend && (
                  <Stack>
                    <StackItem>
                      <Text as="p" variant="medium" styles={InnoStyles.ingressStyle}>
                        Unfortunately you're not authenticated.
                      </Text>
                    </StackItem>
                  </Stack>
                )}
                {authenticationState === AuthenticationState.Authenticated && (
                  <Fragment>
                    <TMGlobalNavigation />
                    <WelcomeScreen userAccountInfo={{name: accountInfo?.account.name, email: accountInfo?.account.userName, isAdmin: isAdmin}} clickedService='' />
                  </Fragment>
                )}
              </Fragment>
            );
          }}
        </AzureAD>
        :
        // Other environments
        <Fragment>
          {!isAuthenticated && !formSend && (
            <Stack>
              <StackItem>
                <Spinner size={SpinnerSize.large} label="Initializing TeamsMate..." />
              </StackItem>
            </Stack>
          )}
          {isAuthenticated && (
            <Fragment>
              <TMGlobalNavigation />
              <WelcomeScreen userAccountInfo={userAccountInfo} clickedService='' subEntityId={subEntityId} />
            </Fragment>
          )}
        </Fragment>
      }
    </Stack>
  );
};

export default App;
