import React, { FormEvent, FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import { IconButton } from '@fluentui/react/lib/Button';
import { CheckboxVisibility, ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { Icon } from '@fluentui/react/lib/Icon';
import { StackItem } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { ITemplateProps } from './Template';
import { RWAGlobalContext } from './../context/RWAGlobalContext';
import * as InnoStyles from '../styles';


export interface ITemplateSelectionProps {
  managementMode: boolean; // management mode for template management, normal mode for team creation
  templates: ITemplateProps[];
  visibleUntilScroll: number; // number of templates visible until scrollbar appears
  onSelectTemplate(templateKey: string): void;
  onDeleteTemplate(templateKey: string): void;
  id: string;
}

const TemplateSelection: FunctionComponent<ITemplateSelectionProps> = ({managementMode, templates, visibleUntilScroll, onSelectTemplate, onDeleteTemplate, id}: ITemplateSelectionProps): ReactElement => {

  const { languageDictionary } = useContext(RWAGlobalContext);
  const [items, setItems] = useState(templates);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [selectedItemKey, setSelectedItemKey] = useState(''); // for management mode

  /**
   * Update list items based on search field value
   * @param _event event
   * @param newValue search field value
   */
  const handleSearchFilterChange = (_event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
    if (newValue) {
      setItems(templates.filter(item => item.name.toLowerCase().indexOf(newValue.toLowerCase()) >= 0));
    } else {
      setItems(templates);
    }
  }

  /**
   * Management mode: set selected item key.
   * Normal mode: select the template.
   * @param item template
   */
  const handleItemSelection = (item: ITemplateProps): void => {
    if (managementMode) {
      setSelectedItemKey(item.key);
    } else {
      onSelectTemplate(item.key);
    }
  }

  const getResultsShownText = (): string => {
    return ` (${languageDictionary.TemplateSelection.ResultsShown.replace('<shownCount>', `${items.length}`).replace('<allCount>', `${templates.length}`)})`;
  }

  /**
   * Set columns for the list
   */
  useEffect(() => {
    const columns : IColumn[] = [
      {
        key: 'icon',
        onRender: item => (
          <Icon className={InnoStyles.TemplateListClassNames.icon} iconName={item.icon} />
        ),
        minWidth: 50,
        maxWidth: 50
      } as IColumn,
      {
        key: 'name',
        onRender: item => (
          <div className={InnoStyles.TemplateListClassNames.itemName}>{item.name}</div>
        ),
        minWidth: 200,
        maxWidth: 300
      } as IColumn,
      {
        key: 'description',
        isMultiline: true,
        onRender: item => (
          <div className={InnoStyles.TemplateListClassNames.itemDescription}>{item.description}</div>
        ),
      } as IColumn
    ];

    // Management mode: show edit icon when item has been selected, and clicking the icon selects the template
    if (managementMode) {
      const editIconColumn = {
        key: 'editIcon',
        onRender: item => (
          selectedItemKey === item.key &&
            <StackItem styles={{ root: { display: 'flex'} }}>
              <IconButton iconProps={{ iconName: 'Edit' }} styles={InnoStyles.iconButtonStyles} title="Edit" ariaLabel="Edit" onClick={() => onSelectTemplate(item.key)}></IconButton>
              <IconButton iconProps={{ iconName: 'Delete' }} styles={InnoStyles.iconButtonStyles} title="Delete" ariaLabel="Delete" onClick={() => onDeleteTemplate(item.key)}></IconButton>
            </StackItem>
        ),
        minWidth: 90,
        maxWidth: 90
      } as IColumn;
      columns.push(editIconColumn);
    }

    setColumns(columns);
  }, [managementMode, selectedItemKey, onSelectTemplate, onDeleteTemplate]);

  return (
    <React.Fragment>
      <TextField
        label={languageDictionary.TemplateSelection.FilterByName + ((items.length === templates.length || !items.length) ? '' : getResultsShownText())}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleSearchFilterChange}
        id={id}
      />
      <DetailsList
        checkboxVisibility={CheckboxVisibility.hidden}
        columns={columns}
        constrainMode={ConstrainMode.unconstrained}
        initialFocusedIndex={undefined}
        isHeaderVisible={false}
        items={items}
        layoutMode={DetailsListLayoutMode.justified}
        onActiveItemChanged={handleItemSelection}
        selectionPreservedOnEmptyClick={true}
        setKey="items"
        styles={{
          root: {
          height: `${visibleUntilScroll * 61}px`,
          overflowX: "hidden",
          overflowY: "auto",
          minWidth: "100%",
          borderBottom: "2px solid #ececec",
          borderTop: "2px solid #ececec",
          marginTop: 10
          },
        }}
      />
    </React.Fragment>
  );
}

export default TemplateSelection;
