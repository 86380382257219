import React, { Fragment, FunctionComponent, ReactElement, useEffect, useState, useContext } from 'react';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { IButtonProps, PrimaryButton } from '@fluentui/react/lib/Button';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { ITeam } from './TMTeamList';
import { ITemplateProps } from './Template';
import { Dropdown, IDropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { RWAPeoplePicker } from './RWAPeoplePicker';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { Icon } from '@fluentui/react/lib/Icon';
import { useBoolean } from '@fluentui/react-hooks';
import * as InnoStyles from './../styles';
import RWAGlobalContext from '../context/RWAGlobalContext';

export interface IMyTeamsDialogProps {
  team?: ITeam | undefined;
  hidden: boolean;
  toggleHideDialog: any;
  templates: ITemplateProps[];
  onArchiveTeam: any;
  onSaveChanges: any;
}

export const MyTeamsDialog: FunctionComponent<IMyTeamsDialogProps> = (props: IMyTeamsDialogProps): ReactElement => {
  const [dropDownOptions, setDropDownOptions] = useState<IDropdownOption[]>([]);
  const [editDialogOwner, setEditDialogOwner] = useState<IPersonaProps[] | undefined>([]);
  const TeamplateDDRef = React.createRef<IDropdown>();
  const [teachingBubbleVisible, { setTrue: showTeachingBubble, setFalse: hideTeachingBubble }] = useBoolean(false);
  const [nameInput, setNameInput] = useState<string>('');
  const [selectedDDOption, setSelectedDDOption] = useState<IDropdownOption>();

  const iconStyles = { marginRight: '8px' };

  const { languageDictionary } = useContext(RWAGlobalContext);

  useEffect(() => {
    props.templates.forEach((element: ITemplateProps, idx: number) => {
      setDropDownOptions(prevState => [...prevState, { key: 'itm_' + element.name, text: element.name, data: { icon: element.icon } }]);
    });
  }, [props.templates]);

  useEffect(() => {
    setEditDialogOwner(props.team?.owners)
    //console.log(props.team?.owners.length);
    let name: string = (props.team as ITeam) === undefined ? '' : (props.team as ITeam).displayName;

    // Determine if template is applied. If so, replace prefix and suffix from template settings to set isolated TeamName
    if (props.team?.ext0exj36kb_TeamsMate !== undefined) {
      const template = props.templates.filter(templ => templ.name === props.team?.ext0exj36kb_TeamsMate.templateName)[0];
      if (template !== undefined) {
        name = name.replace(template.namingPolicy.prefix, '');
        name = name.replace(template.namingPolicy.suffix, '');
      }
    }
    setNameInput(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.team]);

/*   useEffect(() => {
    if (editDialogOwner?.length !== 2) {
      showTeachingBubble();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDialogOwner]); */

  const setOwners = (users: IPersonaProps[]) => {
    setEditDialogOwner(users);
    if (users.length < 2) {
      showTeachingBubble();
    }
  }

  const handleSave = () => {

    // check selected template and whether it has changed
    let templateChanged: boolean = false;
    let selectedTemplate: ITemplateProps;
    if (props.team?.ext0exj36kb_TeamsMate) {
      selectedTemplate = props.templates.filter(template => template.name === props.team?.ext0exj36kb_TeamsMate.templateName)[0];
    } else {
      selectedTemplate = props.templates.filter(template => template.name === selectedDDOption?.text)[0];
      if (selectedTemplate) {
        templateChanged = true;
      }
    }

    // check if name has changed
    let nameChanged: boolean = false;
    const newName: string = (selectedTemplate?.namingPolicy.prefix ?? '') + nameInput + (selectedTemplate?.namingPolicy.suffix ?? '');
    if (newName !== props.team?.displayName) {
      nameChanged = true;
    }

    // check if owners have changed
    let ownersChanged: boolean = false;
    let addedOwner:IPersonaProps[] = [];
    let removedOwner:IPersonaProps[] = [];
    if((editDialogOwner as IPersonaProps[]).length < 2) { showTeachingBubble(); return; }

    let a = JSON.stringify(editDialogOwner);
    let b = JSON.stringify(props.team?.owners);
    if(a !== b) { ownersChanged = true; }
    if(ownersChanged) {
      (editDialogOwner as IPersonaProps[]).forEach(usr => {
        if(props.team !== undefined) {
          if(props.team.owners !== undefined) {
            if(props.team?.owners.filter(i => i.id === usr.id).length === 0 || props.team?.owners === undefined) {
              addedOwner.push(usr);
            }
          } else {
            addedOwner.push(usr);
          }
        }
      });

      if(props.team?.owners !== undefined) {
        props.team?.owners.forEach(usr => {
          if((editDialogOwner as IPersonaProps[]).filter(i => i.id === usr.id).length === 0) {
            removedOwner.push(usr);
          }
        })
      }
    }

    /*console.log('owner changed',ownersChanged);
    console.log('template changed',templateChanged);
    console.log('name changed',nameChanged);
    console.log('added owner', addedOwner);
    console.log('removed owner', removedOwner);*/
    props.onSaveChanges(nameChanged, newName, ownersChanged, templateChanged, selectedTemplate, addedOwner, removedOwner);
  }

  const onRenderOption = (option: any): JSX.Element => {
    return (
      <div>
        {option.data && option.data.icon && (
          <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
        )}
        <span>{option.text}</span>
      </div>
    );
  };

  const incomplientInfoBoxProps: IButtonProps = {
    children: 'Ok',
    onClick: hideTeachingBubble,
  };

  const onTemplateDDChange = (event: any, newValue: any, index: any): void => {
    //console.log('TemplateDDChange', newValue);
    setSelectedDDOption(newValue);
  }

  return (
    <Fragment>
      <Dialog
        title={props.team === undefined ? 'Dialog' : "Team: " + props.team?.displayName}
        styles={InnoStyles.MyTeamsDialogStyle}
        onDismiss={props.toggleHideDialog}
        hidden={props.hidden}
        closeButtonAriaLabel='Close'>
        <Stack>
          <StackItem styles={InnoStyles.MyTeamsDialogContentStyle}>
            <TextField label={languageDictionary.MyTeamsDialog.TeamNameLabelText} defaultValue={nameInput} onChange={(event) => setNameInput((event.target as HTMLInputElement).value)} />&nbsp;
            <Dropdown
              options={dropDownOptions}
              ariaLabel='Apply template'
              label={languageDictionary.MyTeamsDialog.ApplyTemplateLabelText}
              styles={{ dropdownItems: { height: 200 }, root: { width: 250 } }}
              onRenderOption={onRenderOption}
              disabled={props.team?.ext0exj36kb_TeamsMate === undefined ? false : true}
              defaultSelectedKey={props.team?.ext0exj36kb_TeamsMate === undefined ? '' : 'itm_' + props.team.ext0exj36kb_TeamsMate.templateName}
              componentRef={TeamplateDDRef}
              onChange={onTemplateDDChange} />
          </StackItem>
          <StackItem styles={{ root: { width: '88%', marginLeft: 25 } }} id='PeoplePickerBox'>
            <RWAPeoplePicker title={languageDictionary.MyTeamsDialog.OwnersLabelText} mandatory={false} onChangeValue={setOwners} selectedUsers={editDialogOwner}></RWAPeoplePicker>
            { teachingBubbleVisible &&
              <TeachingBubble
                target="#PeoplePickerBox"
                primaryButtonProps={incomplientInfoBoxProps}
                hasSmallHeadline={true}
                onDismiss={hideTeachingBubble}
                headline={languageDictionary.MyTeamsDialog.TeachingBubbleHeadlineText}
                closeButtonAriaLabel="Close"
                styles={{ root: { backgroundColor: 'purple', left: '150px' } }}
                hasCloseButton={true}>
                { languageDictionary.MyTeamsDialog.TeachingBubbleContentText }
              </TeachingBubble>
            }
          </StackItem>
          <StackItem styles={InnoStyles.MyTeamsDialogFooterStyle}>
            <PrimaryButton text={languageDictionary.MyTeamsDialog.SaveButtonText} styles={{ root: { marginRight: 10 } }} onClick={handleSave} />
            <PrimaryButton text={languageDictionary.MyTeamsDialog.CancelButtonText} onClick={props.toggleHideDialog} styles={{ root: { marginRight: 10 } }} />
            <PrimaryButton text={languageDictionary.MyTeamsDialog.ArchiveTeamButtonText} onClick={props.onArchiveTeam} />
          </StackItem>
        </Stack>
      </Dialog>
    </Fragment>
  );
}
