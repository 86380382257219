import React, { createContext, useReducer } from 'react';
import { AuthenticationState } from 'react-aad-msal';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import AppReducer from './AppReducer';
import * as DataLayer from '../DataLayer';
import { dictionaryList, ITranslations } from './../languages';
import * as jwt from 'jsonwebtoken';
import moment from 'moment';
import * as microsoftTeams from "@microsoft/teams-js";
import * as CachedDataLayer from '../CachedDataLayer';
import { MessageBarType, } from '@fluentui/react';


interface ContextState {
  isLocalDevEnv: boolean,
  authState: AuthenticationState.Unauthenticated,
  AADToken: string,
  GraphToken: string,
  TenantID: string,
  userLanguage: string,
  languageDictionary: ITranslations,
  OrderForm: {
    teamDescription: string,
    teamLifecycle: any,
    teamName: string, // including possible prefix and suffix
    selectedTemplateName: string,
    selectedPrimaryOwners: IPersonaProps[],
    selectedSecondaryOwners: IPersonaProps[],
    teamIsPrivate: boolean
  },
  formSend: boolean,
  updateAADToken: any,
  getAndUpdateAADToken: any,
  updateGraphToken: any,
  userLanguageChange: any,
  updateOrderForm: any,
  updateFormSend: any,
  checkTokens: any,
  updateTenantID: any
}

const getGlobalUserLanguage = async () => {
  const settings = await DataLayer.getTMSettings();
  if (settings === undefined) {
    const res = await DataLayer.setTMSettings({ language: 'en' });
    if (res === 'OK') { return 'en' as string; };
  } else {
    return settings['language'] as string;
  }
}

const getDictionary = (lang: string): ITranslations => {
  switch (lang) {
    case 'en':
      return dictionaryList.en;

    case 'de':
      return dictionaryList.de;

    case 'fi':
      return dictionaryList.fi;

    case 'dk':
      return dictionaryList.dk;

    case 'se':
      return dictionaryList.se;

    case 'no':
      return dictionaryList.no;

    default:
      return dictionaryList.en;
  }
}

const _storedLanguage: string = (getGlobalUserLanguage() as unknown) as string;

// Initial state
const initialState: ContextState = {
  isLocalDevEnv: process.env.NODE_ENV.toLowerCase() === 'development',
  authState: AuthenticationState.Unauthenticated,
  AADToken: '',
  GraphToken: '',
  TenantID: '',
  userLanguage: (_storedLanguage as string),
  languageDictionary: getDictionary(_storedLanguage),
  OrderForm: {
    teamDescription: '',
    teamLifecycle: { key: '', text: '' },
    teamName: '', // including possible prefix and suffix
    selectedTemplateName: '',
    selectedPrimaryOwners: [],
    selectedSecondaryOwners: [],
    teamIsPrivate: false
  },
  formSend: false,
  updateAADToken: (newValue: any) => { },
  getAndUpdateAADToken: async (): Promise<string> => { return ''; },
  updateGraphToken: (newValue: any) => { },
  userLanguageChange: (newValue: any) => { },
  updateOrderForm: (OrderForm: any) => { },
  updateFormSend: (newValue: any) => { },
  checkTokens: async (): Promise<[string, string]> => { return ['', ''] },
  updateTenantID: (newValue: any) => { }
}

// Create context
export const RWAGlobalContext = createContext(initialState);

// Provider component
export const RWAGlobalProvider = (props: any) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const updateOrderForm = (OrderForm: any) => {
    try {
      dispatch({
        type: 'UPDATE_ORDERFORM',
        payload: OrderForm
      });
    } catch (error) {
      console.error('dispatch called for updateOrderForm: ' + error);
    }
  }

  const updateAADToken = (newValue: any) => {
    try {
      dispatch({
        type: 'UPDATE_AADTOKEN',
        payload: newValue
      });
    } catch (error) {
      console.error('dispatch called for updateAADToken: ' + error);
    }
  }

  const updateTenantID = async (newValue: any) => {
    try {
      dispatch({
        type: 'UPDATE_TENANTID',
        payload: newValue
      });
    } catch (error) {
      console.error('dispatch called for updateTenantID: ' + error);
    }
  }

  /*
    Verify and check validity of the current active tokens.
    Pays attention to local dev env or production env.
    If token has been expired, fetches a new one.
  */
  const checkTokens = async (): Promise<[string, string]> => {

    if (state.isLocalDevEnv) {
      let t, gt: string = '';
      if (state.AADToken.length > 0) {
        const decoded: { [key: string]: any; } = jwt.decode(state.AADToken) as { [key: string]: any; };
        if (moment(decoded.exp).isAfter(moment())) { // token expired
          t = await getAndUpdateAADToken();
        } else {
          t = state.AADToken;
        }
      } else { t = await getAndUpdateAADToken(); }
      if (state.GraphToken.length > 0) {
        const gDecoded: { [key: string]: any; } = jwt.decode(state.GraphToken) as { [key: string]: any; };
        if (moment(gDecoded.exp).isAfter(moment())) { // token expired
          gt = await DataLayer.getGraphToken(t, state.isLocalDevEnv);
          updateGraphToken(gt);
        } else { gt = state.GraphToken; }
      } else { gt = await DataLayer.getGraphToken(t, state.isLocalDevEnv); updateGraphToken(gt); }
      return Promise.resolve([t, gt]);
    } else { // Prod/Stage environment.
      const decoded: { [key: string]: any; } = jwt.decode(state.AADToken) as { [key: string]: any; };
      const gDecoded: { [key: string]: any; } = jwt.decode(state.GraphToken) as { [key: string]: any; };
      if (moment(decoded.exp).isAfter(moment())) { // token expired
        microsoftTeams.authentication.getAuthToken({
          successCallback: (token: string) => {
            updateAADToken(token);
          }
        })
      } else if (moment(gDecoded.exp).isAfter(moment())) { // token expired
        updateGraphToken(await DataLayer.getGraphToken(state.AADToken, false));
      }

      return Promise.resolve([state.AADToken, state.GraphToken]);
    }
  }


  /**
   * Return AAD token from global context.
   * If it's missing, request it from data layer, save it to global context and return it
   */
  const getAndUpdateAADToken = async (): Promise<string> => {
    if (state.AADToken?.length > 0) {
      return Promise.resolve(state.AADToken);
    } else {
      const newValue: string = await DataLayer.getAADToken(state.isLocalDevEnv);
      updateAADToken(newValue);
      return Promise.resolve(newValue);
    }
  }

  const updateGraphToken = (newValue: any) => {
    try {
      dispatch({
        type: 'UPDATE_GRAPHTOKEN',
        payload: newValue
      });
    } catch (error) {
      console.error('dispatch called for updateGraphToken: ' + error);
    }
  }

  const updateFormSend = (newValue: any) => {
    try {
      dispatch({
        type: 'UPDATE_FORMSEND',
        payload: newValue
      });
    } catch (error) {
      console.error('dispatch called for updateFormSend: ' + error);
    }
  }

  const userLanguageChange = (newValue: any) => {
    DataLayer.setTMSettings({ language: newValue });
    try {
      dispatch({
        type: 'UPDATE_LANGUAGE',
        payload: newValue
      });
    } catch (error) {
      console.error('dispatch called for updateLanguage: ' + error);
    }
  }

  return (
    <RWAGlobalContext.Provider value={{
      isLocalDevEnv: state.isLocalDevEnv,
      authState: state.authState,
      AADToken: state.AADToken,
      GraphToken: state.GraphToken,
      TenantID: state.TenantID,
      userLanguage: state.userLanguage,
      languageDictionary: state.languageDictionary,
      OrderForm: state.OrderForm,
      formSend: state.formSend,
      updateAADToken,
      getAndUpdateAADToken,
      checkTokens,
      updateGraphToken,
      userLanguageChange,
      updateOrderForm,
      updateTenantID,
      updateFormSend
    }}>
      {props.children}
    </RWAGlobalContext.Provider>
  );
}

export default RWAGlobalContext;
