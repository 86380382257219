import React, { useState, useEffect } from 'react'
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import ExtendLifecycle from './ExtendLifecycle';

interface ILifecycleExtendProps {
    urlParams: string;
}

const DEBUG = false;

const LifecycleExtend = (props: ILifecycleExtendProps) => {

    const [teamId, setTeamId] = useState<string>('')
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getContext = () => {
            DEBUG && console.info('LifecycleExtend props: ', { props });
            setLoading(true);
            
            if (props?.urlParams) {
                setTeamId(props.urlParams);
            }

            setLoading(false);
        }

        getContext();
    }, [props]);

    DEBUG && console.info('LifecycleExtend teamId: ', { teamId });

    return (
        <Stack>
            {(loading || teamId === '')
                ? <Spinner size={SpinnerSize.medium} />
                : <ExtendLifecycle teamId={teamId} />
            }
        </Stack>
    )
}

export default LifecycleExtend
