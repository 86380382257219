import React, { Fragment, FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import * as DataLayer from '../DataLayer';
import { RWAGlobalContext } from '../context/RWAGlobalContext';
import { RWAPeoplePicker } from './RWAPeoplePicker';
import * as InnoStyles from '../styles';


interface ITMPermissionManagementProps {
  tabClick: boolean; // value change indicates tab click
}

export const TMPermissionManagement: FunctionComponent<ITMPermissionManagementProps> = (props: ITMPermissionManagementProps): ReactElement => {
  const { languageDictionary, AADToken, GraphToken, checkTokens } = useContext(RWAGlobalContext);
  const [currentAdministrators, setcurrentAdministrators] = useState<IPersonaProps[]>([]);
  const [dbAdmins, setDBAdmins] = useState([]);
  const [SubmittingChanges, setSubmittingChanges] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  /**
   * onCurrentAdministratorChanged triggered each time a new entry has been added or removed
   * 
   * @param items whole set of defined admins, no matter if a new item added or removed.
   */
  const onCurrentAdministratorsChange = (items: IPersonaProps[]): void => {
    setcurrentAdministrators(items);
  };

  /**
   *  onSubmitAdmins Triggered when Submit button has been clicked
   */
  const onSubmitAdmins = () => {

    const added: Array<any> = [];
    const removed: Array<any> = [];

    currentAdministrators.forEach(curItm => {
      if (dbAdmins === undefined || dbAdmins?.filter(i => i['PrincipalId'] === curItm.id).length === 0) {
        added.push(curItm);
      }
    });

    dbAdmins?.forEach(itm => {
      if (currentAdministrators.filter(i => i.id === itm['PrincipalId']).length === 0) {
        removed.push(itm);
      }
    });

    if (added.length > 0) {
      added.forEach(usr => {
        addUserToAdmins(usr);
      });
    }

    if (removed.length > 0) {
      removed.forEach(usr => {
        removeUserFromAdminsViaGraph(usr);
      });
    }

    setSubmittingChanges(true);
  };

  const addUserToAdmins = (user: any) => {
    DataLayer.addAdmin(user, AADToken);
  };

  const removeUserFromAdminsViaGraph = (user: any) => {
    DataLayer.removeAdmin(user['PrincipalId'], user['AppRoleIDGraph'], AADToken);
  };

  /**
   * Retrieve admins into state
   */
  useEffect(() => {
    const retrieveAdmins = async () => {
      await checkTokens();
      setShowLoadingSpinner(true);
      const adminsFromDb: any = await DataLayer.getAllAdmins(AADToken);
      setDBAdmins(adminsFromDb);
      let adminsFromTenant: IPersonaProps[] = [];
      if (adminsFromDb?.length > 0) {
        let adminsChecked: number = 0; // number of admins checked
        adminsFromDb.map((adminFromDb: any) => (
          DataLayer.getUserById(adminFromDb.PrincipalId, GraphToken).then(foundUser => {
            if (foundUser) {
              adminsFromTenant.push({
                text: foundUser.displayName,
                id: foundUser.id,
                title: foundUser.displayName,
                imageInitials: foundUser.givenName?.substr(0, 1) + foundUser.surname?.substr(0, 1),
                secondaryText: foundUser.jobTitle,
              });
            }
            adminsChecked++;
          }).catch(error => {
            adminsChecked++;
            if (error.response.status === 404) {
              removeUserFromAdminsViaGraph(adminFromDb);
            }
            console.error(`There was an error trying to get the user: ${error.message}`)
          }).finally(()=> {
            console.log(`Checked admins: ${adminsChecked} and Admins from db: ${adminsFromDb.length}`);

            if (adminsChecked >= adminsFromDb.length) {
              setcurrentAdministrators(adminsFromTenant);
              setShowLoadingSpinner(false);
            }
          })
        ));
          
      } else {
        setShowLoadingSpinner(false);
      }
    }
    retrieveAdmins();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AADToken, GraphToken, props.tabClick]);

  useEffect(() => {
    setTimeout(() => { setSubmittingChanges(false); }, 5000);
  }, [SubmittingChanges]);

  return (
    <Stack as="fieldset" styles={InnoStyles.fieldSetStyles} role="form">
      <Text as="legend" styles={InnoStyles.legendStyles} variant="large">{languageDictionary.Admin.ManagePermissionsTitle}</Text>
      {showLoadingSpinner && (
        <Spinner label={languageDictionary.Admin.RetrievingAdmins} size={SpinnerSize.large} />
      )}
      {!showLoadingSpinner && (
        <Fragment>
          <Stack tokens={InnoStyles.formStackTokens} horizontal={false} grow>
            <StackItem grow>
              <RWAPeoplePicker title={languageDictionary.Admin.AddOrRemoveAdmins} mandatory={false} onChangeValue={onCurrentAdministratorsChange} selectedUsers={currentAdministrators}></RWAPeoplePicker>
            </StackItem>
          </Stack>
          <Stack horizontal tokens={InnoStyles.formStackTokens}>
            <StackItem>
              <PrimaryButton text={languageDictionary.Admin.Submit} styles={InnoStyles.primaryButtonStyles} onClick={onSubmitAdmins}></PrimaryButton>
            </StackItem>
          </Stack>
        </Fragment>
      )}
      {SubmittingChanges && (
        <MessageBar isMultiline={false} messageBarType={MessageBarType.success}>
          <Text as="p">{languageDictionary.Admin.ChangesSaved}</Text>
        </MessageBar>
      )}
    </Stack>
  );
}
