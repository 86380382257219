import React, { RefObject, useEffect, useState, useContext } from "react";
import { Nav, INavLinkGroup, INavLink, INav, INavStyles } from '@fluentui/react/lib/Nav';
import { Icon } from '@fluentui/react/lib/Icon';
import { ActionButton, Label } from "@fluentui/react";
import { RWAGlobalContext } from '../context/RWAGlobalContext';

export interface TMChannelTreeProps {
  onNavLinkClicked: (item: any) => void;
  onChannelAdd: (item: any) => void;
  onChannelRemove: (item:any) => void;
  DropZoneName: string;
  TMRef: RefObject<INav>;
  Channels?: Array<any>;
}

export const TMChannelTree: React.FC<TMChannelTreeProps> = (props) => {

  const { languageDictionary } = useContext(RWAGlobalContext);
  const [dzName] = useState(props.DropZoneName);
  const [navLinksGroup, setNavLinksGroup] = useState<INavLinkGroup[]>([{    
    name: dzName,
    links: [],
  }]);
  //const [navLinks, setNavLinks] = useState<INavLink[]>([]);

  const _onRenderGroupHeader = (group:any) => {
    return <strong style={{lineHeight: "34px", display: "block", margin: "5px 5px", fontSize: 16, paddingLeft: 10, fontWeight: 500}}>{group.name} <Icon iconName="More" styles={{root: {float: "right", marginRight: 10, fontSize: "20px"}}}></Icon></strong>;
  }

  const NavLinkClicked = (link:INavLink | undefined) => {
    props.onNavLinkClicked(link);
  }

  const navStyles: INavStyles = {
    root: {
        backgroundColor: "#fff",
        border: '1px solid #CCC',
        minHeight: 299
    },
    link: {height: 36 },
    linkText: {},
    compositeLink: {},
    chevronButton: {},
    chevronIcon: {},
    navItems: { },
    navItem: {},
    group: {},
    groupContent: {},
  }

  useEffect(() => {
    if(props.Channels !== undefined) {
      let tmpChan: INavLink[] = [];
      props.Channels.forEach((navL,idx) => {
          tmpChan.push({ name: navL.displayName, url: '', key: 'key' + (idx + 1), target: '' });
      });
      setNavLinksGroup([{ name: dzName, links: tmpChan}]);
    }
  }, [dzName, props.Channels]);

  return (
      <React.Fragment>
        <div style={{ width: '100%', display: "flex", minHeight: "100%", boxSizing: "border-box", flexDirection: "column" }}>
          <Label style={{display: "block", marginBottom: -10}}>
            <ActionButton iconProps={{ iconName: 'Delete' }} title="Click to remove channel" ariaLabel="Click to remove a channel" onClick={props.onChannelRemove} styles={{ root: { cursor: 'pointer', float: "right", fontSize: 13, top: -11 } }}>
              {languageDictionary.TMChannelTree.RemoveSelectedText}
            </ActionButton>
            <ActionButton iconProps={{ iconName: 'AddTo' }} title="Click to add a channel" ariaLabel="Click to add a channel" onClick={props.onChannelAdd} styles={{ root: { cursor: 'pointer', float: "right", fontSize: 13, top: -11 } }}>
            {languageDictionary.TMChannelTree.AddChannelText}
            </ActionButton>
            <span style={{display: "inline-block"}}>{languageDictionary.TMChannelTree.ChannelsText}</span>
          </Label>
          
          <Nav styles={navStyles}
            onRenderGroupHeader={_onRenderGroupHeader}
            groups={navLinksGroup}
            onLinkClick={(ev, item?) => NavLinkClicked(item)}
            componentRef={props.TMRef}
          />
        </div>
    </React.Fragment>
    
  );
};
