import React, { FunctionComponent, ReactElement, useContext, useState } from 'react';
import { IPivotItemProps, Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { TMTeamManagement } from './TMTeamManagement';
import { RWAGlobalContext } from '../context/RWAGlobalContext';
import { TMTemplateManagement } from './TMTemplateManagement';
import { TMTeamsReportRequest } from './TMTeamsReportRequest';
import { TMPermissionManagement } from './TMPermissionManagement';
import { TMDatabaseContentRetrieval } from './TMDatabaseContentRetrieval';
import * as InnoStyles from '../styles';


interface ITMAdministrationProps {
  userEmail?: string; // TODO: add user info to global context
}

export const TMAdministration: FunctionComponent<ITMAdministrationProps> = (props: ITMAdministrationProps): ReactElement => {
  const { languageDictionary } = useContext(RWAGlobalContext);

  // value change indicates tab click
  const [templateManagementTabClick, setTemplateManagementTabClick] = useState(false);
  const [permissionManagementTabClick, setPermissionManagementTabClick] = useState(false);
  const [refreshTeams, setRefreshTeams] = useState(false);

  /**
   * Handle tab selection
   * @param selectedPivotItemProps selected pivot item props
   */
  const handleTabSelection = (selectedPivotItemProps: IPivotItemProps | undefined) => {
    switch (selectedPivotItemProps?.headerText) {
      case languageDictionary.Admin.TabManageTeams:
        setRefreshTeams(!refreshTeams);
        break;
      case languageDictionary.Admin.TabManageTemplates:
        setTemplateManagementTabClick(!templateManagementTabClick);
        break;
      case languageDictionary.Admin.ManagePermissionsTab:
        setPermissionManagementTabClick(!permissionManagementTabClick);
    }
  }

  return (
    <Stack>
      <Text as="p" variant="medium" styles={InnoStyles.ingressStyle}>
        {/* Temporary removed any heading text here. If needed replace with some useful text */}
      </Text>
      <Pivot
        aria-label="Admin navigation"
        onLinkClick={(pivotItem: PivotItem | undefined) => handleTabSelection(pivotItem?.props)}
        overflowBehavior="menu" 
        styles={{root: {marginLeft: -8}}}>
        <PivotItem headerText={languageDictionary.Admin.TabManageTeams}>
          <TMTeamManagement adminMode={true} refreshTeams={refreshTeams} />
        </PivotItem>
        <PivotItem headerText={languageDictionary.Admin.RequestTeamsReportTab}>
          <TMTeamsReportRequest userEmail={props.userEmail} />
        </PivotItem>
        <PivotItem headerText={languageDictionary.Admin.TabManageTemplates}>
          <TMTemplateManagement tabClick={templateManagementTabClick} />
        </PivotItem>
        <PivotItem headerText={languageDictionary.Admin.ManagePermissionsTab}>
          <TMPermissionManagement tabClick={permissionManagementTabClick} />
        </PivotItem>
        <PivotItem headerText={languageDictionary.Admin.RetrieveDatabaseContentTab}>
          <TMDatabaseContentRetrieval userEmail={props.userEmail} />
        </PivotItem>
      </Pivot>
    </Stack>
  );
}
