import axios from 'axios';
import { createStore, get, set, del } from 'idb-keyval';
import moment from 'moment';
import { ITemplateProps } from './components/Template';

//const GRAPH_ENDPOINT = process.env.REACT_APP_API_GRAPH_ENDPOINT;
const ADD_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT;

//const TMAdminStore = createStore('teamsmateAdminDB', 'AdminStorage');
//const TMSettingsStore = createStore('teamsmateSettings', 'SettingsStorage');


const isStoreDataExpired = async (storeKey:string, tenantID: string) : Promise<boolean> => {
    if(tenantID === '' || tenantID === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMDataGeneral = createStore('teamsmateDB-' + tenantID, 'General');
    return get(storeKey + 'Exp', TMDataGeneral).then(val => { // check date stored in db
        if(moment(val).isBefore(moment().subtract(12, 'hours'))) { // if date is older then 24h, wipe data
            return true;
        } else {
            return false;
        }
    });
}

export const getCachedTMServices = async (token: string, tenantID:string) : Promise<any> => {

    if(token.length < 10) {
        Promise.reject('Invalid token passed');
    }
    if(tenantID === '' || tenantID === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMDataGeneral = createStore('teamsmateDB-' + tenantID, 'General');
    let storeData = await get('tcs/gettmservices', TMDataGeneral);
    const expired = await isStoreDataExpired('tcs/gettmservices', tenantID);
    if(storeData === undefined) {
        const reply = await axios({ url: '/tcs/gettmservices',  method: 'POST', baseURL: ADD_ENDPOINT, headers: { authorization : 'Bearer ' + token  } });
        await set('tcs/gettmservices', reply.data, TMDataGeneral)
        await set('tcs/gettmservicesExp', new Date().toUTCString(), TMDataGeneral);
        return Promise.resolve(reply.data);
    } else {
        if(!expired) {
            return await get('tcs/gettmservices', TMDataGeneral);
        } else {
            del('tcs/gettmservices', TMDataGeneral);
            del('tcs/gettmservicesExp',TMDataGeneral);
            const reply = await axios({ url: '/tcs/gettmservices',  method: 'POST', baseURL: ADD_ENDPOINT, headers: { authorization : 'Bearer ' + token  } });
            await set('tcs/gettmservices', reply.data, TMDataGeneral)
            await set('tcs/gettmservicesExp', new Date().toUTCString(), TMDataGeneral);
            return Promise.resolve(reply.data);
        }
    }
}

export const getCachedAllTemplates = async (token: string, tenantId: string) : Promise<any> => {
    
    if(token.length < 10) {
        return Promise.reject('Invalid token passed');
    }
    if(tenantId === '' || tenantId === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMDataGeneral = createStore('teamsmateDB-' + tenantId, 'General');
    const storeData = await get('tcs/getalltemplates', TMDataGeneral);
    const expired = await isStoreDataExpired('tcs/getalltemplates',tenantId);
    if(storeData === undefined) {
        let templates: ITemplateProps[] = [];
        const reply = await axios({ url: '/tcs/getalltemplates',  method: 'POST', data: `{ tenantId: ${tenantId} }`, baseURL: ADD_ENDPOINT, headers: { authorization : 'Bearer ' + token  } })
        if(reply.data.Table1 !== undefined) {
            reply.data.Table1.forEach((item: any) => {
                const template: ITemplateProps = {
                    key: item['TemplateId'],
                    name: item['TemplateName'].trim(), // database value contains extra whitespaces
                    description: item['Description'],
                    namingPolicy: {prefix: JSON.parse(item['NamingPolicy']).prefix, suffix: JSON.parse(item['NamingPolicy']).suffix},
                    lifecycle: item['Lifecycle'].trim(), // database value contains extra whitespaces
                    icon: item['IconName'],
                    templateJson: item['Template']
                };
                templates.push(template);
            });
        } else {
            return Promise.reject('No templates available, create some.');
        }
        await set('tcs/getalltemplates', templates, TMDataGeneral)
        await set('tcs/getalltemplatesExp', new Date().toUTCString(), TMDataGeneral);
        return Promise.resolve(templates);
    } else {
        if(!expired) {
            return Promise.resolve(await get('tcs/getalltemplates', TMDataGeneral));
        } else {
            await del('tcs/getalltemplates', TMDataGeneral)
            await del('tcs/getalltemplatesExp',TMDataGeneral);
            let templates: ITemplateProps[] = [];
            const reply = await axios({ url: '/tcs/getalltemplates',  method: 'POST', data: `{ tenantId: ${tenantId} }`, baseURL: ADD_ENDPOINT, headers: { authorization : 'Bearer ' + token  } })
            reply.data.Table1.forEach((item: any) => {
                const template: ITemplateProps = {
                    key: item['TemplateId'],
                    name: item['TemplateName'].trim(), // database value contains extra whitespaces
                    description: item['Description'],
                    namingPolicy: {prefix: JSON.parse(item['NamingPolicy']).prefix, suffix: JSON.parse(item['NamingPolicy']).suffix},
                    lifecycle: item['Lifecycle'].trim(), // database value contains extra whitespaces
                    icon: item['IconName'],
                    templateJson: item['Template']
                };
                templates.push(template);
            });
            await set('tcs/getalltemplates', templates, TMDataGeneral)
            await set('tcs/getalltemplatesExp', new Date().toUTCString(), TMDataGeneral);
            return Promise.resolve(templates);
        }
    }
}