import axios from 'axios';
import { authProviderAAD } from './authProvider/authProviderAAD';
import { authProviderGraph } from './authProvider/authProviderGraph';
import { ITemplateProps } from './components/Template';
import { createStore, get, set, del, clear } from 'idb-keyval';
import moment from 'moment';
const GRAPH_ENDPOINT = process.env.REACT_APP_API_GRAPH_ENDPOINT;
const ADD_ENDPOINT = process.env.REACT_APP_API_BASE_ENDPOINT;


const TMSettingsStore = createStore('teamsmateSettings', 'SettingsStorage');


export const setTMSettings = async (tmSettings: any): Promise<string> => {
    return new Promise((resolve, reject) => {
        del('settings', TMSettingsStore);
        set('settings', tmSettings, TMSettingsStore).then(() => {
            resolve('OK');
        }).catch(err => {
            reject(err);
        })
    });
};

export const getTMSettings = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
        get('settings', TMSettingsStore).then((val) => {
            resolve(val);
        }).catch(err => {
            reject(err);
        })
    });
};

/**
 * Store a array of extensionInfo into the users indexedDB.
 * @param extensionInfo Array of extensionInfo
 * @returns a string of OK if successful
 */
export const setAdminExtensionInfo = async (extensionInfo: any[], tenantID: string): Promise<string> => {
    if (tenantID === '' || tenantID === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMAdminStore = createStore('teamsmateAdminDB-' + tenantID, 'AdminStorage');
    return new Promise((resolve, reject) => {
        set('teamlistExtensionInfo', extensionInfo, TMAdminStore).then(() => {
            set('teamlistDate', new Date().toUTCString(), TMAdminStore);
            resolve('OK');
        }).catch((err) => {
            reject(err);
        })
    })
};

/**
 * Get all extensionInfo data from stored indexedDB
 * @returns an array of extensionInfo data
 */
export const getAdminExtensionInfo = async (tenantID: string): Promise<any[]> => {
    if (tenantID === '' || tenantID === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMAdminStore = createStore('teamsmateAdminDB-' + tenantID, 'AdminStorage');
    return new Promise((resolve, reject) => {
        get('teamlistExtensionInfo', TMAdminStore).then((extensionInfo: any[]) => {
            resolve(extensionInfo);
        }).catch((err) => {
            reject(err);
        })
    });
};

/**
 * Store a set of teams to indexedDB
 * @param teams Teams to be stored in indexedDB
 * @returns a string with OK if successful
 */
export const setAllAdminGroups = async (teams: any[], tenantID: string): Promise<string> => {
    if (tenantID === '' || tenantID === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMAdminStore = createStore('teamsmateAdminDB-' + tenantID, 'AdminStorage');
    return new Promise((resolve, reject) => {
        set('teamlist', teams, TMAdminStore).then(() => {
            set('teamlistDate', new Date().toUTCString(), TMAdminStore);
            resolve('OK');
        }).catch((err) => {
            reject(err);
        })
    })
};

/**
 * Retrieve all Admin groups from indexedDB storage.
 * If data is older then 24h(1day), wipe data and enfore a refresh
 */
export const getAllAdminGroups = async (tenantID: string): Promise<any[]> => {
    if (tenantID === '' || tenantID === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMAdminStore = createStore('teamsmateAdminDB-' + tenantID, 'AdminStorage');
    return new Promise((resolve, reject) => {
        get('teamlistDate', TMAdminStore).then(val => { // check date stored in db
            if (moment(val).isBefore(moment().subtract(1, 'days'))) { // if date is older then 24h, wipe data
                del('teamlist', TMAdminStore);
                del('teamlistDate', TMAdminStore);
                resolve([]); // resolve empty
            }
            get('teamlist', TMAdminStore).then((teams: any[]) => {
                resolve(teams);
            })
        }).catch((err) => {
            reject(err);
        })
    });
};

/**
 * Retrieve AAD token
 * @param isLocalDevEnv whether this is local development environment
 */
export const getAADToken = async (isLocalDevEnv: boolean = false): Promise<string> => {
    return new Promise((resolve, reject) => {
        if (isLocalDevEnv) {
            authProviderAAD.getAccessToken({ scopes: [process.env.REACT_APP_SCOPE_AAD_API as string] })
                .then(resp => {
                    resolve(resp.accessToken);
                }).catch(error => {
                    reject(error);
                })
        } else {
            resolve(''); // TODO: get auth token in other environments (needed if token is not yet available from global context when it's requested)
        }
    });
}

/**
* Retrieve Graph token
* @param aadToken token to be used in the authorization header and to be exchanged to an access token for graph
* @param isLocalDevEnv whether this is local development environment
*/
export const getGraphToken = async (aadToken: string, isLocalDevEnv: boolean = false): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (isLocalDevEnv) {
            authProviderGraph.getAccessToken({ scopes: [process.env.REACT_APP_SCOPE_USER_READBASIC_ALL as string] })
                .then(resp => {
                    resolve(resp.accessToken);
                })
                .catch(error => {
                    reject(error);
                })
        } else {
            axios({ url: '/tcs/gbt', method: 'POST', data: { "token": aadToken }, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + aadToken } })
                .then((response: any) => {
                    resolve(response.data.access_token);
                })
                .catch((error) => {
                    reject('Error while retrieving Graph token: ' + error);
                });
        }
    });
}

/**
 * Retrieves the TeamsMate services available to current user/customer
 * 
 * @param token The authentication token to be passed to the endpoint being called
 */
export const getTMServices = async (token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/gettmservices', method: 'POST', baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('error retrieving services: ' + error);
                reject('Error retrieving services: ' + error);
            });
    });
}

/**
 * Find user by id from tenant
 * 
 * @param id user id to be used against Graph API for finding a user
 * @param token authentication token to be passed to the endpoint being called
 */
export const getUserById = async (id: string, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!token) { reject('getUserById: token cannot be empty or undefined'); }
        axios({ url: `/v1.0/users/${id}`, method: 'GET', data: '', baseURL: GRAPH_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
}

/**
 * Find user by search term from tenant
 * 
 * @param searchTerm search term (string) to be used against Graph API for finding a user
 * @param token authentication token to be passed to the endpoint being called
 */
export const getPartialUsers = async (searchTerm: string, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!token) { reject('getPartialUsers: token cannot be empty or undefined'); }
        axios({ url: `/v1.0/users?$filter=startswith(displayName,'${searchTerm}') or startswith(mail,'${searchTerm}') or startswith(surname, '${searchTerm}')`, method: 'GET', data: '', baseURL: GRAPH_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data.value);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export const getUsersJoinedTeams = async (userid: string, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        if (!token) { reject('getUsersJoinedTeams: token cannot be empty or undefined'); }
        axios({ url: `/v1.0/users/${userid}/joinedTeams`, method: 'GET', data: '', baseURL: GRAPH_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data.value);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export const getTeamDetails = async (teamID: string, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: `/v1.0/teams/${teamID}`, method: 'GET', data: '', baseURL: GRAPH_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export const getTeamMembers = async (teamID: string, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: `/v1.0/teams/${teamID}/members`, method: 'GET', data: '', baseURL: GRAPH_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export const getTeamChannels = async (teamID: string, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: `/v1.0/teams/${teamID}/channels`, method: 'GET', data: '', baseURL: GRAPH_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data.value);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export const getChannelMessages = async (teamID: string, channelID: string, token: string, limit: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: `/v1.0/teams/${teamID}/channels/${channelID}/messages/delta?$top=${limit}`, method: 'GET', data: '', baseURL: GRAPH_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data.value);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

export const getChannelMessageReplies = async (teamID: string, channelID: string, messageID: string, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: `/v1.0/teams/${teamID}/channels/${channelID}/messages/${messageID}/replies`, method: 'GET', data: '', baseURL: GRAPH_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data.value);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export const getGraphNextLinkContent = async (nextLink: string, token: string): Promise<any> => {
    return new Promise((resolve, reject) => { // groups?$expand=extensions&$select=id,resourceProvisioningOptions,ext0exj36kb_TeamsMate
        axios({ url: '/tcs/getgraphnextlink', method: 'POST', data: { "nextLink": nextLink }, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}

export const getGroups = async (token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/getgroups', method: 'POST', baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error('error retrieving services: ' + error);
                reject('Error retrieving services: ' + error);
            });
    });
}

/*
export const getGroups = async (token: string) : Promise<any> => {
    return new Promise((resolve,reject) => { // groups?$expand=extensions&$select=id,resourceProvisioningOptions,ext0exj36kb_TeamsMate
        axios({ url: `/beta/groups?$expand=extensions&$select=id,displayName,resourceProvisioningOptions,ext0exj36kb_TeamsMate&$filter=resourceProvisioningOptions/Any(x:x eq 'Team')&$count=true&$orderby=displayName asc`,  method: 'GET', data: '', baseURL: GRAPH_ENDPOINT, headers: { authorization : 'Bearer ' + token, 'ConsistencyLevel': 'eventual' } })
        .then((response:any) => {
            resolve(response.data);
        })
        .catch((error) => {
            console.log(error)
            reject(error);
        });
    });
}
*/

/**
 * getAllTemplates return all customers template by calling the TeamsMate API endpoint.
 * 
 * @param token The authentication token to be send in the header of the request
 * @param tenantId The current customers tenant id
 */
export const getAllTemplates = async (token: string, tenantId: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/getalltemplates', method: 'POST', data: `{ tenantId: ${tenantId} }`, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                let templates: ITemplateProps[] = [];
                response.data.Table1.forEach((item: any) => {
                    const template: ITemplateProps = {
                        key: item['TemplateId'],
                        name: item['TemplateName'].trim(), // database value contains extra whitespaces
                        description: item['Description'],
                        namingPolicy: { prefix: JSON.parse(item['NamingPolicy']).prefix, suffix: JSON.parse(item['NamingPolicy']).suffix },
                        lifecycle: item['Lifecycle'].trim(), // database value contains extra whitespaces
                        icon: item['IconName'],
                        templateJson: item['Template']
                    };
                    templates.push(template);
                });
                resolve(templates);
            })
            .catch((error) => {
                console.error('error retrieving template: ' + error);
                reject('Error while retrieving templates: ' + error);
            });
    });
}

/**
* Call TeamsMate API to create a template
* @param payload request body
* @param token token to be used in authorization header
*/
export const createTemplate = async (payload: ITemplateProps, token: string, tenantID: string): Promise<any> => {
    if (tenantID === '' || tenantID === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMDataGeneral = createStore('teamsmateDB-' + tenantID, 'General');
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/createtemplate', method: 'POST', data: payload, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                clear(TMDataGeneral);
                resolve(response.status as number);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * Call TeamsMate API to edit a template
 * @param payload request body
 * @param token token to be used in authorization header
 */
export const editTemplate = async (payload: ITemplateProps, token: string, tenantID: string): Promise<any> => {
    if (tenantID === '' || tenantID === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMDataGeneral = createStore('teamsmateDB-' + tenantID, 'General');
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/edittemplate', method: 'POST', data: payload, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                clear(TMDataGeneral);
                resolve(response.status as number);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * Call TeamsMate API to delete a template
 * @param payload request body
 * @param token token to be used in authorization header
 */
export const deleteTemplate = async (payload: any, token: string, tenantID: string): Promise<any> => {
    if (tenantID === '' || tenantID === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMDataGeneral = createStore('teamsmateDB-' + tenantID, 'General');
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/deletetemplate', method: 'POST', data: payload, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                clear(TMDataGeneral);
                resolve(response.status as number);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * Call TeamsMate API to create a team
 * @param payload request body
 * @param token token to be used in authorization header
 */
export const createTeam = async (payload: any, token: string) => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/create', method: 'POST', data: payload, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.status as number);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * Call TeamsMate API to edit a team
 * @param payload request body
 * @param token token to be used in authorization header
 */
export const editTeam = async (payload: any, token: string, tenantID: string): Promise<any> => {
    if (tenantID === '' || tenantID === undefined) {
        return Promise.reject('invalid tenantID');
    }
    const TMAdminStore = createStore('teamsmateAdminDB-' + tenantID, 'AdminStorage');
    return new Promise((resolve, reject) => {
        // Whenever we update a team, wipe the indexedDB cache storage, so it gets refreshed.
        del('teamlistExtensionInfo', TMAdminStore);
        del('teamlist', TMAdminStore);
        axios({ url: '/tcs/editteam', method: 'POST', data: payload, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.status as number);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * Call TeamsMate API to archive a team
 * @param teamId team ID
 * @param token token to be used in authorization header
 */
export const archiveTeam = async (teamId: string, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/archiveteam', method: 'POST', data: { "TeamId": teamId }, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * Search groups by the provided name in the payload
 * 
 * @param payload request body to be send to TeamsMate API
 * @param token authentication token to be passed to TeamsMate API
 */
export const searchGroups = async (payload: any, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/searchgroups', method: 'POST', data: payload, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data.value);
            })
            .catch((error) => {
                reject('Error while searching groups: ' + error);
            });
    });
}

/**
 * getAllAdmin retrieves all registered admins for the current customer.
 * 
 * @param token the authentication token to be passed to the TeamsMate API
 */
export const getAllAdmins = async (token: string) => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/getalladmins', method: 'POST', baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data.Table1);
            })
            .catch((error) => {
                reject('Error while retrieving admins: ' + error);
            });
    });
}

/**
 * addAdmin Adds and admin to the Graph and TeamsMate customer database.
 * 
 * @param payload JSON object to be passed to the TeamsMate API as payload. -> { UserEmail: <email>, UserID: <AAD-ID> }
 * @param token the authentication token to be passed to the TeamsMate API.
 */
export const addAdmin = async (payload: any, AADtoken: string) => {
    //const appRoleIDGraph = await setGraphAdmin(payload['id'], Graphtoken);
    return new Promise((resolve, reject) => {
        const postData = {
            UserEmail: payload['itemProp'],
            UserID: payload['id'],
        };
        axios({ url: '/tcs/addadmin', method: 'POST', data: postData, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + AADtoken } })
            .then((response: any) => {
                resolve(response.status as number);
            })
            .catch((error) => {
                reject('Error while setting admins: ' + error);
            });
    });
}

/**
 * Remove admin from Graph and TeamsMate customer database
 * 
 * @param userid PrincipalId in AdminUser table
 * @param graphroleid AppRoleIDGraph in AdminUser table
 * @param aadToken authentication token to be passed to the TeamsMate API
 */
export const removeAdmin = async (userid: string, graphroleid: string, aadToken: string) => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/removeadmin', method: 'POST', data: { UserID: userid, AppRoleIDGraph: graphroleid }, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + aadToken } })
            .then((response: any) => {
                resolve(response.status as number);
            })
            .catch((error) => {
                reject('Error while setting admins: ' + error);
            });
    });
}

/**
* Call API to send database content via email
* @param payload request body to be send to the API
* @param token token to be used in the authorization header
*/
export const sendTeamsReport = async (payload: any, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/sendteamsreport', method: 'POST', data: payload, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.status as number);
            })
            .catch((error) => {
                reject('Error while sending teams report: ' + error);
            });
    });
}

/**
* Call API to send database content via email
* @param payload request body to be send to the API
* @param token token to be used in the authorization header
*/
export const sendDatabaseContent = async (payload: any, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/senddbcontent', method: 'POST', data: payload, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.status as number);
            })
            .catch((error) => {
                reject('Error while sending database content: ' + error);
            });
    });
}

export const ExecuteGraphBatch = async (payload: any, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios({ url: '/tcs/executegraphbatch', method: 'POST', data: { "batchData": payload }, baseURL: ADD_ENDPOINT, headers: { authorization: 'Bearer ' + token } })
            //axios({ url: '/v1.0/$batch',  method: 'POST', data: payload, baseURL: GRAPH_ENDPOINT, headers: { authorization : 'Bearer ' + token } })
            .then((response: any) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject('Error while sending database content: ' + error);
            });

    });
}
