import { Icon } from '@fluentui/react/lib/Icon';
import React from 'react';
import { useDrag } from 'react-dnd';

let disabled = false;

export const buttonDivstyle: React.CSSProperties = {
	border: '1px solid gray',
	backgroundColor: 'white',
	padding: '0.3rem 0.8rem',
	marginRight: '1rem',
	marginBottom: '1rem',
	cursor: 'move',
  float: 'left',
  textDecoration: 'none',
  borderRadius: 5,
  fontSize: 14,
  position: 'relative',
  opacity: disabled ? '0.5' : '1'
}

export const disabledButtonDivstyle: React.CSSProperties = {
  opacity: 0.5
}

export const buttonStyle:  React.CSSProperties = {
  lineHeight: '20px', 
  paddingLeft: 25
}


export const buttonIconStyle:  React.CSSProperties = {
  color: '#ae1c84', 
  verticalAlign: 'middle', 
  position: 'absolute', 
  top: 0, left: 0, 
  bottom: 0, 
  backgroundColor: '#EFEFEF',
  borderRight: "1px solid #CCC",
  width: 30, 
  textAlign: 'center', 
  borderTopLeftRadius: 5, 
  borderBottomLeftRadius: 5, 
  paddingTop: 7
}


export interface TMDnDBoxProps {
	name: string
  type: string
  data: any
  isDropped: boolean
  icon: string
}

export const TMDnDBox: React.FC<TMDnDBoxProps> = ({ name, type, isDropped, icon, data }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [{ opacity }, drag] = useDrag({
      item: { name, type, icon, data },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    })
  
    return (
      <div ref={drag} style={buttonDivstyle}>
        {isDropped ? <span style={buttonStyle}><Icon style={buttonIconStyle} iconName={icon} /> {name}</span> : name}
      </div>
    )
  }


