import React, { FunctionComponent, ReactElement, useContext } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { CheckboxVisibility, ConstrainMode, DetailsList, DetailsListLayoutMode, SelectionMode, IColumn, IDetailsListProps, IDetailsRowStyles, DetailsRow } from '@fluentui/react/lib/DetailsList';
import { GraphTeam } from './GraphTeamInterfaces';
import { Pagination } from '@uifabric/experiments';
import RWAGlobalContext from './../context/RWAGlobalContext';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { SearchBox } from '@fluentui/react/lib/SearchBox';


export interface ITeam extends GraphTeam {
  lastMessageActivity: string;
  owners: IPersonaProps[];
}

export interface ITMTeamListProps {
  teams: ITeam[];
  pageCount: number;
  onMyTeamsPageChange: any;
  currentPage: number;
  onListItemInvoke:any;
  onSearchTermChange: any;
}

const TMTeamList: FunctionComponent<ITMTeamListProps> = (props: ITMTeamListProps): ReactElement => {

  const { userLanguage, languageDictionary } = useContext(RWAGlobalContext);

  const onOwnerColumnRender = (item: ITeam, index: number, column: IColumn) => {
    if(item.owners !== undefined && item.owners.length > 0) {
      return <span>{item.owners.map((itm:IPersonaProps) => { return itm.text }).join(', ') }</span>;
    } else {
      return <span></span>
    }
  }

  const onTemplateColumnRender = (item: ITeam, index: number, column: IColumn) => {
    const templateName = item.ext0exj36kb_TeamsMate === undefined ? 'None' : item.ext0exj36kb_TeamsMate.templateName;
    return <span>{templateName}</span>;
  }

  const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      //TODO: Check if owner of team and then show or remove pointer cursor css
      
      customStyles.root = { cursor: 'pointer' }
      
      return <DetailsRow {...props} styles={customStyles} />;
    }
    return null;
  };
  
  return (
    <Stack>
      <SearchBox placeholder={languageDictionary.TeamList.FilterByNameText} onSearch={newVal => { props.onSearchTermChange(null, newVal) } } onClear={ev => { props.onSearchTermChange(null, ''); } } />
      <DetailsList
        items={props.teams}
        columns={[
          { key: 'name1', name: languageDictionary.TeamList.ListHeaderNames.Name , fieldName: 'displayName', minWidth: 100, maxWidth: 200, isResizable: true, isSorted: true },
          { key: 'created2', name: languageDictionary.TeamList.ListHeaderNames.Created, fieldName: 'createdDateTime', minWidth: 100, maxWidth: 200, isResizable: true, onRender: (item: ITeam) => (<>{new Date(item.createdDateTime).toLocaleString(userLanguage, { timeZone: 'UTC' })}</>) },
          { key: 'archived4', name: languageDictionary.TeamList.ListHeaderNames.Archived, fieldName: 'isArchived', minWidth: 100, maxWidth: 100, isResizable: true },
          { key: 'visibility5', name: languageDictionary.TeamList.ListHeaderNames.Visibility, fieldName: 'visibility', minWidth: 100, maxWidth: 100, isResizable: true },
          { key: 'tmTemplate', name: languageDictionary.TeamList.ListHeaderNames.Template, fieldName: 'templateName', onRender: onTemplateColumnRender, minWidth: 150, maxWidth: 100, isResizable: true },
          { key: 'owners6', name: languageDictionary.TeamList.ListHeaderNames.Owners, fieldName: 'owners', onRender : onOwnerColumnRender,  minWidth: 100, maxWidth: 200, isResizable: true, isMultiline: true }
        ] as IColumn[]}
        layoutMode={DetailsListLayoutMode.justified}
        selectionMode={SelectionMode.none}
        setKey='detailedTeams'
        constrainMode={ConstrainMode.unconstrained}
        onItemInvoked={props.onListItemInvoke}
        onRenderRow={_onRenderRow}
        checkboxVisibility={CheckboxVisibility.hidden}
        usePageCache={true}
        >
      </DetailsList>
      <Pagination
        selectedPageIndex={(props.currentPage -1)}
        pageCount={props.pageCount}
        onPageChange={props.onMyTeamsPageChange}
        firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
        previousPageIconProps={{ iconName: 'ChevronLeft' }}
        nextPageIconProps={{ iconName: 'ChevronRight' }}
        lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
        format='buttons'
        styles={{ root: { paddingBottom: 10, paddingTop: 10 } }}
      />
    </Stack>
  );
}

export default TMTeamList;
