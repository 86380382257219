import React, { FunctionComponent, ReactElement, useContext, useEffect, useState } from 'react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { Text } from '@fluentui/react/lib/Text';
import { TextField } from '@fluentui/react/lib/TextField';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import * as Constants from '../Constants';
import * as DataLayer from '../DataLayer';
import { RWAGlobalContext } from '../context/RWAGlobalContext';
import * as InnoStyles from '../styles';


interface ITMDatabaseContentRetrievalProps {
  userEmail?: string;
}

export const TMDatabaseContentRetrieval: FunctionComponent<ITMDatabaseContentRetrievalProps> = (props: ITMDatabaseContentRetrievalProps): ReactElement => {
  const { AADToken, languageDictionary, checkTokens } = useContext(RWAGlobalContext);
  const [email, setEmail] = useState(props.userEmail);
  const [emailValid, setEmailValid] = useState(false);
  const [sending, setSending] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFailure, setSendFailure] = useState(false);

  /**
   * Handle send button click
   */
  const handleSendClick = async () => {
    setSending(true);
    await checkTokens();
    DataLayer.sendDatabaseContent({ "userEmail": email?.trim() }, AADToken)
    .then((statusCode) => {
      statusCode === 200 ? setSendSuccess(true) : setSendFailure(true);
    })
    .catch((error) => {
      setSendFailure(true);
      console.error(error);
    })
    .finally(() => {
      setSending(false);
    });
  }

  useEffect(() => {
    if (email) {
      setEmailValid(Constants.validEmail.test(email.trim()));
    } else {
      setEmailValid(false);
    }
  }, [email]);

  /**
   * Hide messages after a delay
   */
  useEffect(() => {
    if (sendSuccess) {
      setTimeout(() => { setSendSuccess(false); }, 10000);
    }
    if (sendFailure) {
      setTimeout(() => { setSendFailure(false); }, 5000);
    }
  }, [sendSuccess, sendFailure]);

  return (
    <Stack as="fieldset" styles={InnoStyles.fieldSetStyles} role="form">
      <Text as="legend" styles={InnoStyles.legendStyles} variant="large">{languageDictionary.Admin.RetrieveDatabaseContentTitle}</Text>
      <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
        <StackItem grow styles={InnoStyles.stackItemStyles}>
          <TextField
            label={languageDictionary.Admin.DatabaseContentEmail}
            onChange={(event) => setEmail((event.target as HTMLInputElement).value)}
            required
            value={email}>
          </TextField>
        </StackItem>
      </Stack>
      <Stack tokens={InnoStyles.formStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} grow>
        <StackItem>
          <PrimaryButton
            disabled={!emailValid || sending || sendSuccess}
            onClick={handleSendClick}
            styles={!emailValid || sending || sendSuccess ? InnoStyles.primaryButtonDisabledStyles : InnoStyles.primaryButtonStyles}
            text={languageDictionary.Admin.Send}>
          </PrimaryButton>
        </StackItem>
        <StackItem styles={InnoStyles.verticallyCenteredStyles}>
          {sending && (<Spinner size={SpinnerSize.medium} />)}
        </StackItem>
      </Stack>
      <Stack horizontal tokens={InnoStyles.formStackTokens}>
        {sendSuccess &&
          <MessageBar isMultiline={false} messageBarType={MessageBarType.success}>
            <Text as="p">{languageDictionary.Admin.EmailSendSuccess}</Text>
          </MessageBar>
        }
        {sendFailure &&
          <MessageBar isMultiline={false} messageBarType={MessageBarType.error}>
            <Text as="p">{languageDictionary.Admin.EmailSendFailed}</Text>
          </MessageBar>
        }
      </Stack>
    </Stack>
  );
}
