import React, { FunctionComponent, ReactElement, useContext } from 'react';
import { DetailsList } from '@fluentui/react/lib/DetailsList';
import { RWAGlobalContext } from '../context/RWAGlobalContext';

const CreationSummaryTable: FunctionComponent = (operations: any): ReactElement => {
  const { languageDictionary } = useContext(RWAGlobalContext);

  const columns = [
    { key: 'column1', name: languageDictionary.TeamCreation.TeamName, fieldName: 'teamname', minWidth: 100, isResizable: true },
    { key: 'column2', name: languageDictionary.TeamCreation.TeamDescription, fieldName: 'teamdescription', minWidth: 100, isResizable: true },
    { key: 'column3', name: languageDictionary.TeamCreation.TeamPrimaryOwner, fieldName: 'primaryowner', minWidth: 100, isResizable: true },
    { key: 'column4', name: languageDictionary.TeamCreation.TeamSecondaryOwner, fieldName: 'secondaryowner', minWidth: 100, isResizable: true },
    { key: 'column5', name: languageDictionary.TeamCreation.TeamLifecycle, fieldName: 'lifecycle', minWidth: 100, isResizable: true },
    { key: 'column6', name: languageDictionary.TeamCreation.TeamPrivacy, fieldName: 'teamprivacy', minWidth: 100, isResizable: true },
    { key: 'column7', name: languageDictionary.TeamCreation.SelectedTeamTemplate, fieldName: 'selectedtemplate', minWidth: 100, isResizable: true },
  ];

  return (
    <div data-is-scrollable={true}>
      <div className={`s-Grid-col`}>
        <DetailsList
          items={operations.children}
          columns={columns}
          selectionMode={0}>
        </DetailsList>
      </div>
    </div>
  );
}

export default CreationSummaryTable;
