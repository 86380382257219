import React, { Fragment, FunctionComponent, ReactElement, useContext, useState, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { RWAGlobalContext } from './../context/RWAGlobalContext';
import { IUserAccountInfo } from '../App';
import { TMServices } from './TMServices';
import { TMAdministration } from './TMAdministration';
import CreationForm from './CreationForm';
import CreationSummary from './CreationSummary';
import { TMTeamManagement } from './TMTeamManagement';
import LifecycleExtend from './LifecycleExtend';
import * as InnoStyles from '../styles';


export interface IWelcomeScreenProps {
  userAccountInfo?: IUserAccountInfo;
  clickedService: string;
  subEntityId?: string;
}

const WelcomeScreen: FunctionComponent<IWelcomeScreenProps> = (props: IWelcomeScreenProps): ReactElement => {
  const [clickedService, setclickedService] = useState(props.clickedService);
  const { formSend, languageDictionary } = useContext(RWAGlobalContext);
  const [lifeCycleExtender, setLifeCycleExtender] = useState(false);
  const [lifecycleParams, setLifeCycleParams] = useState<string>('');
  let location = useLocation();


  const onServiceClicked = (event: any) => {
    if ((event.target as HTMLElement).innerText.toLowerCase().indexOf(languageDictionary['Teams creation service'].toLowerCase()) > -1) {
      setclickedService('tcs');
    }

    else if ((event.target as HTMLElement).innerText.toLowerCase().indexOf(languageDictionary.Settings.toLowerCase()) > -1) {
      setclickedService('settings');
    }

    else if ((event.target as HTMLElement).innerText.toLowerCase().indexOf(languageDictionary['Bot service'].toLowerCase()) > -1) {
      setclickedService('bot');
    }

    else if ((event.target as HTMLElement).innerText.toLowerCase().indexOf(languageDictionary['My teams'].toLowerCase()) > -1) {
      setclickedService('myteams');
    }
  }

  useEffect(() => {
    if((location as any).state !== undefined) {
      if(((location as any).state.Home))
        setclickedService('');
    }
  }, [location]);

  useEffect(() => {
    const getSubEntityId = () => {
      if (props?.subEntityId && props.subEntityId !== '') {
        setLifeCycleParams(props.subEntityId);
        setLifeCycleExtender(true);
      }
    };
    getSubEntityId();

  }, [props.subEntityId]);

  return (
    <Fragment>
      {lifeCycleExtender && <Redirect to="/lifecycle" />}
      {clickedService === "tcs" && <Redirect to="/createTeam" />}
      {clickedService === "tcs" && formSend && <Redirect to="/tosummary" />}
      <Stack styles={InnoStyles.welcomeStackStyle}>
        {window.location.pathname === '/' && clickedService.length === 0 && !formSend &&
          <Text as="p" variant="medium" styles={InnoStyles.WelcomeText}>
            {languageDictionary.HelloText} {props.userAccountInfo?.name}, {languageDictionary.HowHelpText}
          </Text>
        }
        {clickedService === "settings" && <Redirect to="/settings" />}
        {clickedService === "myteams" && <Redirect to="/myteams" />}
      </Stack>
      <Stack horizontal styles={InnoStyles.welcomeStackStyle}>
        <StackItem align="start" key="MainArea" styles={InnoStyles.WelcomeStackItemCore}>
          <Switch>
            <Route path="/lifecycle">
              <LifecycleExtend urlParams={lifecycleParams} />
            </Route>
            <Route path="/createTeam">
              <CreationForm
                userName={props.userAccountInfo?.name}
                teamNameCharLimit={Number(process.env.REACT_APP_TEAM_NAME_CHAR_LIMIT ?? '')}
                teamDescriptionCharLimit={Number(process.env.REACT_APP_TEAM_DESCRIPTION_CHAR_LIMIT ?? '')}>
              </CreationForm>
            </Route>
            <Route path="/tosummary">
              <CreationSummary />
            </Route>
            <Route path="/myteams">
              <TMTeamManagement adminMode={false} />
            </Route>
            {props.userAccountInfo?.isAdmin &&
              <Route path="/settings">
                <TMAdministration userEmail={props.userAccountInfo?.email} />
              </Route>
            }
            <Route path="/">
              <TMServices customClickEvent={onServiceClicked} isUserAdmin={props.userAccountInfo?.isAdmin} />
            </Route>
          </Switch>
        </StackItem>
        {/* {window.location.pathname === '/' && clickedService.length === 0 && !formSend &&
          <StackItem key="NewsArea" styles={InnoStyles.WelcomeStackItemNews} tokens={{ padding: "0px 20px 20px 20px" }}>
            <Text as="h2" styles={InnoStyles.h2Style}>{languageDictionary.WelcomeScreen.AnnouncementText}</Text>
            <TMAnnouncements />
          </StackItem>
        } */}
      </Stack>
    </Fragment>
  );
}

export default WelcomeScreen;
