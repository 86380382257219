import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Configuration, Logger, LogLevel } from "msal";

const logger = new Logger(
  (logLevel, message, containsPii) => {
    console.log("[MSAL]", message);
  },
  {
    level: LogLevel.Error,
    piiLoggingEnabled: false,
  }
);

// Msal Configurations
const config: Configuration = {
  auth: {
    authority: "https://login.microsoftonline.com/common",
    clientId: process.env.REACT_APP_AADCLIENTID ?? "",
    
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    validateAuthority: false,

    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: false,
  },
  // Enable logging of MSAL events for easier troubleshooting.
  // This should be disabled in production builds.
  system: {
    logger: logger as any,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["https://graph.microsoft.com/User.ReadBasic.All"]
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/graphauth.html",
};

export const authProviderGraph = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
