import en from './en.json';
import de from './de.json';
import fi from './fi.json';
import dk from './dk.json';
import no from './no.json';
import se from './se.json';


export interface ITranslations {
  Home: string,
  AvailableServicesText: string,
  HelloText: string,
  HowHelpText: string,
  'Teams creation service': string,
  Settings: string,
  'Bot service': string,
  'My teams': string,
  'Hook service': string,
  MessageError: string,
  SelectAnOption: string,
  Months: string,
  Years: string,
  Option3Months: string,
  Option6Months: string,
  Option1Year: string,
  Option2Years: string,
  Option3Years: string,
  Public: string,
  Private: string,
  Admin: {
    TabManageTeams: string,
    TabManageTemplates: string,
    RequestTeamsReportTab: string,
    RequestTeamsReportTitle: string,
    ManagePermissionsTab: string,
    ManagePermissionsTitle: string,
    RetrieveDatabaseContentTab: string,
    RetrieveDatabaseContentTitle: string,
    InactivityThreshold: string,
    TeamsReportEmail: string,
    SendRequest: string,
    TeamsReportSent: string,
    RetrievingAdmins: string,
    AddOrRemoveAdmins: string,
    Submit: string,
    ChangesSaved: string,
    DatabaseContentEmail: string,
    Send: string,
    EmailSendSuccess: string,
    EmailSendFailed: string
  },
  WelcomeScreen: {
    AnnouncementText: string
  },
  TeamCreation: {
    CharactersLeft: string,
    Congratulations: string,
    CreateAnotherTeam: string,
    CreateTeam: string,
    CreateTeamAnyway: string,
    NoteRow1: string,
    NoteRow2: string,
    NoteRow3: string,
    NoteRow4: string,
    PleaseNote: string,
    SelectedTeamTemplate: string,
    SelectTeamTemplate: string,
    SimilarlyNamedTeamsExist: string,
    Spinner: string,
    SummaryOfTeamDetails: string,
    TeachingBubbleHeadline: string,
    TeachingBubbleMaintext: string,
    TeamDescription: string,
    TeamDetails: string,
    TeamLifecycle: string,
    TeamLifecycleTooltip: string,
    TeamName: string,
    TeamNamePrefix: string,
    TeamNamePrefixSuffix: string,
    TeamNameSuffix: string,
    TeamPrimaryOwner: string,
    TeamPrivacy: string,
    TeamSecondaryOwner: string
  },
  TeamManagement: {
    TooFewOwnersText: string,
    MyTeamsText: string,
    AllTeamsText: string,
    ChangesToTeamAppliedText: string,
    ErrorApplyingChangesText: string,
    TeamSuccessArchivedText: string,
    ErrorArchivingTeamText: string,
    NoSearchResultsFound: string
  },
  TeamList: {
    ListHeaderNames: {
      Name: string,
      Created: string,
      Archived: string,
      Visibility: string,
      Template: string,
      Owners: string
    },
    FilterByNameText: string
  },
  TemplateSelection: {
    FilterByName: string,
    ResultsShown: string
  },
  MyTeamsDialog: {
    TeamNameLabelText: string,
    ApplyTemplateLabelText: string,
    OwnersLabelText: string,
    TeachingBubbleHeadlineText: string,
    TeachingBubbleContentText: string,
    SaveButtonText: string,
    CancelButtonText: string,
    ArchiveTeamButtonText: string
  },
  TemplateManagement : {
    DnDBoxPlannerText: string,
    DndBoxWebLinkText: string,
    ReturnBtnStateText: string,
    ReturnBtnBackText: string,
    DnDBoxPropertiesText: string,
    DeleteTemplateText: string,
    DeleteTemplateModalBtnOkText: string,
    DeleteTemplateModalBtnCancelText: string,
    CloseBtnText: string,
    SaveBtnText: string,
    RemoveBtnText: string,
    CancelBtnText: string,
    CreateChannelDialogTitleText: string,
    ChannelNameText: string,
    EditingTeamTemplateText: string,
    CreateNewTeamTemplateText: string,
    SelectTeamTemplateEditText: string,
    CreateNewTemplateText: string,
    TemplateNameText: string,
    TemplateDescriptionText: string,
    TeamNamePrefixText: string,
    TeamNameSuffixText: string,
    TeamLifecycleLabelText: string,
    TeamIconLabelText: string,
    ForExampleIconText: string,
    UnsavedTemplateText: string,
    DnDItemToChannelText: string,
    ChannelTabsText: string,
    TemplateSavedText: string,
    TemplateRemovedText: string,
    ErrorDeletingTemplateMsgText: string,
    SaveTemplateBtnText: string,
    SpinnerLabelText: string,
    DnDClickToChangeText: string,
    DnDReleaseDropText: string
  },
  TMChannelTree: {
    RemoveSelectedText: string,
    AddChannelText: string,
    ChannelsText: string
  },
  PeoplePicker: {
    SuggestionHeaderText: string,
    MostRecentlyUsedHeaderText: string,
    NoResultFoundText: string,
    LoadingText: string,
    SuggestionsAvailableAlertText: string,
    SuggestionsContainerAriaLabel: string,
  },
  TMHookService: {
    HookServiceDescriptionText: string,
    ParamatersText: string,
    ResetButtonText: string,
    MethodLabelText: string,
    TestHookButtonText: string,
    EnableGlobalHookLabelText: string,
    SaveHookButtonText: string,
    UrlToCallLabel: string,
    IncludeTexts: {
      IncludeTemplateNameText: string,
      IncludeTeamNameText: string,
      IncludeTeamDescriptionText: string,
      IncludeTeamOwnersText: string,
      IncludeTeamVisibilityText: string,
      IncludeTeamLifecycleText: string,
    },
    LoadingMessages: {
      SavingText: string,
      TestingText: string
    },
    SuccessMessages: {
      EditingHookSuccessText: string,
      TestHookCalled1: string,
      TesthookCalled2: string
    },
    ErrorMessages: {
      ErrorInvalidUrlText: string,
      ErrorNoCallHook: string,
      ErrorCallTestHookText:string,
      ErrorParseParametersText: string,
      ErrorUpdateHookText: string,
      ErrorGetHookPropertiesText: string
    }
  }
};

interface IDictionaryList {
  en: ITranslations,
  de: ITranslations,
  fi: ITranslations,
  dk: ITranslations,
  no: ITranslations,
  se: ITranslations
};

export const dictionaryList: IDictionaryList = { en, de, fi, dk, no, se };

export const languageoptions = {
  en: 'English',
  fi: 'Suomi',
  dk: 'Dansk',
  no: 'Norsk',
  se: 'Svenska',
  de: 'Deutsch'
};
