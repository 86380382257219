import React, { Fragment, FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
export interface ITMMessageBarProps {
  message?: string;
  messageHTML?: JSX.Element;
  messageType: MessageBarType;
  visible: boolean;
  timeout?: number;
}

const TMMessageBox: FunctionComponent<ITMMessageBarProps> = (props: ITMMessageBarProps): ReactElement => {
  const [visible, setVisible] = useState(props.visible);

  useEffect(() => {
    setVisible(props.visible);
    if (props.visible && props.timeout && props.timeout > 0) {
      setTimeout(() => {
        setVisible(false)
      }, props.timeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  return (
    <Fragment>
      {visible && (
        <Stack>
          <StackItem>
            <MessageBar
              dismissButtonAriaLabel="Close"
              isMultiline={true}
              messageBarType={props.messageType}
              onDismiss={() => setVisible(false)}
              styles={{
                text: {
                  fontSize: "14px"
                }, 
                root: { marginTop: '5px' }
              }}>
              {props.message ? props.message : props.messageHTML}
            </MessageBar>
          </StackItem>
        </Stack>
      )}
    </Fragment>
  );
}

export default TMMessageBox;
