import React, { Fragment, FunctionComponent, ReactElement, useContext } from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { Icon } from '@fluentui/react/lib/Icon';
import { Link } from '@fluentui/react/lib/Link';
import { Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import RWAGlobalContext from './../context/RWAGlobalContext';
import * as InnoStyles from './../styles';
import { useHistory } from 'react-router';


const TMGlobalNavigation: FunctionComponent = (): ReactElement => {

  const { userLanguageChange, languageDictionary, userLanguage } = useContext(RWAGlobalContext);
  let his = useHistory();


  const LanguageDropDownOptions: IDropdownOption[] = [
    { key: 'en', text: 'English' },
    { key: 'fi', text: 'Suomi' },
    { key: 'dk', text: 'Dansk' },
    { key: 'no', text: 'Norsk' },
    { key: 'se', text: 'Svenska' },
    { key: 'de', text: 'Deutsch' },
  ];

  const changeLanguage = (_event: any, ItemOption: IDropdownOption | undefined, index?: any) => {
    userLanguageChange((ItemOption as IDropdownOption).key);
  }

  const navigateHome = () => {
    his.push('/', { Home: true });
  }

  /*
style={{ 
            color: 'white',
            float: 'left',
            display: 'flex',
            alignItems: 'center',
            height: 50,
            padding: '0 15px',
            opacity: 1,
            fontFamily: "Segoe UI",
            textDecoration: 'none',
          }}
        >
*/

// styles={InnoStyles.TMGlobalNavigationLinkStyles}
  return (
    <Fragment>
      <Stack horizontal styles={InnoStyles.TMGlobalNavigation}>
        <Text styles={InnoStyles.TMGlobalNavigationTitleStyles}>TeamsMate</Text>
        <Link 
          title="Home" 
          onClick={navigateHome}
          styles={InnoStyles.TMGlobalNavigationLinkStyles}
          >
          <Icon iconName="Home" title="Home" />&nbsp;{languageDictionary.Home}
        </Link>
        <Text styles={{ root: { position: 'absolute', right: '120px', fontSize: 12, color: 'white' } }}>© {new Date().getFullYear()} Innofactor</Text>
        <Dropdown
          placeholder="Language"
          options={LanguageDropDownOptions}
          onChange={changeLanguage}
          selectedKey={userLanguage}
          styles={{ root: { position: 'absolute', right: '10px' } }}>
        </Dropdown>
      </Stack>
    </Fragment>
  );
}

export default TMGlobalNavigation;
