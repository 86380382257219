/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, FunctionComponent, ReactElement, useState, useEffect, useContext } from 'react'
import TMMessageBox from './TMMessageBox';
import { MessageBarType } from '@fluentui/react/lib/MessageBar';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack, StackItem } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import * as jwt from 'jsonwebtoken';
import { TMServiceCard, ITMServiceCardProps } from './TMServiceCard';
import * as InnoStyles from '../styles';
import { RWAGlobalContext } from './../context/RWAGlobalContext';
import * as CachedDataLayer from '../CachedDataLayer';


export interface ITMServicesProps {
    customClickEvent: any;
    isUserAdmin?: boolean;
}

/** 
 * React component for listing all available Services
*/
export const TMServices: FunctionComponent<ITMServicesProps> = (props: ITMServicesProps): ReactElement => {

    const [dummy] = useState(false);
    const [tmServices, settmServices] = useState<ITMServiceCardProps[]>([]);
    const [showMessage, setshowMessage] = useState(false); // Show MessageBox -> TMMessageBox?
    const { languageDictionary, checkTokens, TenantID} = useContext(RWAGlobalContext);

    const getServiceIcon = (serviceName: any) => {
        switch (serviceName) {
            case 'Teams creation service':
                return 'TeamsLogo';
            case 'My teams':
                return 'Teamwork';
            case 'Bot service':
                return 'ChatBot';
            case 'Settings':
                return 'Settings';
            default:
                return '';
        }
    }

    const onServiceClicked = (event: any) => {
        props.customClickEvent(event);
    }

    useEffect(() => {
        const retrieveServices = async () => {
            const tokens = await checkTokens();
            const decoded: { [key: string]: any; } = jwt.decode(tokens[0]) as { [key: string]: any; };
            const _tmservices = await CachedDataLayer.getCachedTMServices(tokens[0], decoded.tid); // local dev: AAD token is missing at this moment from global context -> need to retrieve it
            let _tmpServices: ITMServiceCardProps[] = [];
            _tmservices.services.forEach((service: any) => {
                _tmpServices.push({
                    icon: getServiceIcon(service),
                    title: service,
                });
            });

            if (props.isUserAdmin === false) {
                settmServices(_tmpServices.filter((itm => (itm.title !== 'Settings'))));

            } else {
                settmServices(_tmpServices);
            }
        }
        retrieveServices();
    }, []);

    return (
        <Fragment>
            <TMMessageBox message="Error: I got scared..." visible={showMessage} messageType={MessageBarType.error} timeout={5000} />
            <Text as="h2" styles={InnoStyles.h2Style}>{languageDictionary.AvailableServicesText}</Text>
            <Stack tokens={InnoStyles.sectionStackTokens} horizontal={!InnoStyles.isSmallMobileDevice.matches} wrap>
                {!tmServices.length && <StackItem styles={{ root: { padding: 50, margin: '0 auto' } }}><Text><Spinner styles={{ root: { marginBottom: 10 } }} size={SpinnerSize.large} /> Loading...</Text></StackItem>
                }
                {tmServices.length &&
                    tmServices.map((itm, idx) => {
                        return <TMServiceCard key={idx} {...itm} customClickEvent={onServiceClicked}></TMServiceCard>
                    })
                }
            </Stack>
        </Fragment>
    )
}
