import React, { useState, useContext } from "react";
import { Icon } from "@fluentui/react/lib/Icon";
import { Label } from "@fluentui/react/lib/Label";
import { useDrop } from "react-dnd";
import { buttonDivstyle, buttonIconStyle, buttonStyle } from "./TMDnDBox";
import { RWAGlobalContext } from './../context/RWAGlobalContext';

const style: React.CSSProperties = {
  height: "12rem",
  maxWidth: "100%",
  marginRight: "0rem",
  marginBottom: "0rem",
  color: "#000",
  padding: "1rem",
  textAlign: "left",
  fontSize: "1rem",
  lineHeight: "normal",
  float: "none",
  overflowX: 'hidden',
  overflowY: 'auto',
  outline: '3px dotted rgb(55, 55, 55, 0.5)',
  outlineOffset: '-2px',
  position: 'relative'
};

export interface TMDropZoneProps {
  accept: string[];
  onDrop: (item: any) => void;
  onPropertiesClick: (item: any) => void;
  content: any[];
  DropZoneName: string;
}

export const TMDropZone: React.FC<TMDropZoneProps> = ({
  accept,
  onDrop,
  content,
  DropZoneName,
  onPropertiesClick
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [dzName] = useState(DropZoneName);
  const { languageDictionary } = useContext(RWAGlobalContext);

  const isActive = isOver && canDrop;
  let backgroundColor = "#FFF";
  if (isActive) {
    backgroundColor = "#efefef";
  } else if (canDrop) {
    backgroundColor = "#FFF";
  }

  return (
      <React.Fragment>
      <Label>{dzName} <span style={{fontWeight: "normal"}}></span>
      </Label>
      <div ref={drop} style={{ ...style, backgroundColor }}>
        {isActive
        ? <span style={{fontSize: '24px', textAlign: 'center', position: 'absolute', left: 0, right: 0, fontWeight: 'bolder', color: 'gray', top: '40%'}}>{languageDictionary.TemplateManagement.DnDReleaseDropText}</span>
        : ""}
          { content && content.map((itm, idx) => (
              <div key={idx} style={buttonDivstyle} onClick={(ev) => onPropertiesClick(itm)}>
              <span style={buttonStyle}><Icon style={buttonIconStyle} iconName={itm['iconName']} /> {itm.name}</span>
              <span style={{fontSize: 12, display: 'block', paddingLeft: 25}}>{languageDictionary.TemplateManagement.DnDClickToChangeText}</span>
              </div>
          ))}
      </div>
    </React.Fragment>
    
  );
};
